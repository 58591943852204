//Hooks
import { useState } from 'react'
import { useDispatch } from 'react-redux'
//Components
import { FiEdit3 } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import 'react-toggle/style.css'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { FaCircle } from 'react-icons/fa6'
//Utils
import {
  editWebsiteNameAsync,
  removeWebsiteAsync,
  toggleNotifications,
  toggleActive,
} from 'features/website/websiteSlice'
import { setError } from 'features/error/errorSlice'
import { open } from 'features/apiKeys/apiKeysSlice'
const Website = ({ website }) => {
  const dispatch = useDispatch()
  const { id, name, integrations, forms } = website
  //Toogles
  const [notifications, setNotifications] = useState(website.notifications)
  const [active, setActive] = useState(website.active)
  //Editing name
  const [websiteName, setWebsiteName] = useState(name)
  const [editing, setEditing] = useState(false)
  //Integrations
  const options = [
    {
      label: 'Gravity',
      value: 'Gravity',
    },
    { label: 'HubSpot', value: 'HubSpot' },
    { label: 'Formidable', value: 'Formidable' },
    { label: 'Typeform', value: 'Typeform' },
    { label: 'Jotform', value: 'Jotform' },
  ]
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])
  //Forms
  const totalForms = forms?.length
  //Removing website
  const removeWebsite = () => {
    try {
      dispatch(removeWebsiteAsync({ id }))
    } catch (err) {
      dispatch(setError('Failed to remove website'))
    }
  }
  //Notifications
  const handleNotificationsToggle = () => {
    try {
      dispatch(toggleNotifications({ id, notifications: !notifications }))
      setNotifications(!notifications)
    } catch (err) {
      dispatch(setError('Failed to toggle notifications'))
    }
  }
  //Active
  const handleActiveToggle = () => {
    try {
      dispatch(toggleActive({ id, active: !active }))
      setActive(!active)
    } catch (err) {
      dispatch(setError('Failed to chaghed active status'))
    }
  }
  //Update name
  const updateName = () => {
    try {
      dispatch(editWebsiteNameAsync({ id, name: websiteName }))
    } catch (err) {
      dispatch(setError('Failed to edit website name'))
    }
  }
  return (
    <div className="flex flex-col items-center justify-between w-full gap-2 p-4 mb-5 border border-gray-700 tabShape sm:flex-row-reverse ">
      <button
        className="hidden sm:block btn btn-primary"
        onClick={removeWebsite}
      >
        remove
      </button>
      <button
        className="self-end btn btn-circle sm:hidden text-primary"
        onClick={removeWebsite}
      >
        <RiDeleteBin5Fill />
      </button>
      <div className="flex flex-col w-full gap-4 lg:flex-row ">
        <div className="w-full max-w-[200px] break-all flex items-center justify-between relative">
          {editing ? (
            <input
              type="text"
              value={websiteName}
              onBlur={() => {
                setEditing(false)
                updateName()
              }}
              onChange={(e) => {
                setWebsiteName(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  setEditing(false)
                  updateName()
                }
              }}
              className="w-full input input-bordered"
            />
          ) : (
            <p className="pr-4">{name}</p>
          )}
          {!editing && (
            <button
              onClick={() => {
                setEditing(!editing)
                if (!editing) {
                  updateName()
                }
              }}
              className="absolute right-[5px]"
            >
              <FiEdit3 />
            </button>
          )}
        </div>
        <div className="flex flex-col justify-between w-full gap-5 xs:items-center xs:flex-row">
          <div className="flex items-center justify-start flex-1 xs:justify-center">
            <div>
              <p className="text-sm">Integrations:</p>
              <div className="dropdown">
                <div
                  tabIndex={0}
                  role="button"
                  className="shadow-xl px-4 w-[160px] py-2 flex items-center justify-center gap-2 "
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {selectedOption.label}{' '}
                  <button
                    className={`border text-center px-[3px] rounded-xl text-red-500  border-red-500 cursor-pointer ${
                      integrations.find(
                        (integration) =>
                          integration.type === selectedOption.value,
                      ) && '!text-primary !border-primary'
                    } `}
                    onClick={(e) => {
                      e.stopPropagation() // Prevent the dropdown from toggling when button is clicked
                      dispatch(
                        open({
                          website: website.id,
                          integration: selectedOption.value,
                        }),
                      )
                    }}
                  >
                    {integrations.find(
                      (integration) =>
                        integration.type === selectedOption.value,
                    )
                      ? 'change'
                      : 'connect'}
                  </button>
                </div>
                {dropdownOpen && (
                  <ul
                    tabIndex={0}
                    className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52"
                  >
                    {options.map((option) => (
                      <li key={option.label}>
                        <p
                          className="flex justify-between"
                          onClick={() => {
                            setSelectedOption(option)
                            setDropdownOpen(false)
                          }}
                        >
                          {option.label}
                          <FaCircle
                            className={`${
                              integrations.find(
                                (integration) =>
                                  integration.type === option.value,
                              ) && '!text-green-500'
                            } text-red-500`}
                          />
                        </p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 h-[80px] flex justify-center items-center flex-col ">
            <p className="self-start xs:self-center">
              Total forms: {totalForms}
            </p>
            <div className="flex justify-between text-gray-500 w-[100px] self-start xs:self-center">
              <Link
                to="/forms"
                state={{ website: website.id, filter: true }}
                className="border  rounded-xl border-primary px-[5px] hover:bg-primary hover:text-slate-50 w-full text-primary text-center"
              >
                view
              </Link>
              {/* <Link
                to="/forms"
                state={{ website: website.id, add: true }}
                className="border  rounded-xl border-primary px-[5px] hover:bg-primary hover:text-slate-50 w-[45px] text-center"
              >
                add
              </Link> */}
            </div>
          </div>
          <div className="flex-1 h-[80px] flex flex-col justify-center xs:items-center text-sm">
            <div className="flex items-center justify-between mb-2 xs:w-[150px]">
              <p>Notifications: </p>
              <input
                type="checkbox"
                className="toggle toggle-success"
                defaultChecked={notifications}
                onChange={handleNotificationsToggle}
              />
            </div>
            <div className="flex items-center justify-between xs:w-[150px] ">
              <p>Active: </p>
              <input
                type="checkbox"
                className="toggle toggle-success"
                defaultChecked={active}
                onChange={handleActiveToggle}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Website
