import { configureStore } from '@reduxjs/toolkit'
//Slices
import formsSlice from 'features/forms/formsSlice'
import websiteReducer from '../features/website/websiteSlice'
import authReducer from '../features/auth/authSlice'
import errorSlice from '../features/error/errorSlice'
import userInfoSlice from '../features/userInfo/userInfoSlice'
import apiKeysSlice from '../features/apiKeys/apiKeysSlice'
export const store = configureStore({
  reducer: {
    forms: formsSlice,
    websites: websiteReducer,
    auth: authReducer,
    error: errorSlice,
    userInfo: userInfoSlice,
    apiKeys: apiKeysSlice,
  },
})
