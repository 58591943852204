import React from 'react'
//Hooks
import { useSelector } from 'react-redux'
const Notifications = () => {
  const slackChannel = useSelector((state) => state.auth.slackChannel)
  const user = useSelector((state) => state.auth.email)
  const email = useSelector((state) => state.auth.email)
  return (
    <div>
      <h2 className="text-lg font-semibold">Slack notifications</h2>
      <div className="divider"></div>
      <p>
        {slackChannel && 'Notifications go to:'} {slackChannel || ''}
      </p>
      <button className="mt-2 btn btn-primary">
        <a
          href={`https://slack.com/oauth/v2/authorize?client_id=215881133136.7237385815187&scope=incoming-webhook,chat:write,channels:join&redirect_uri=${process.env.REACT_APP_BASE_URL}/slack/auth&state=${user}`}
        >
          {slackChannel ? 'Change Channel' : 'Install Slack'}
        </a>
      </button>
      <h2 className="mt-10 text-lg font-semibold">Email notifications</h2>
      <div className="divider"></div>
      <p>Email notifications go to: {email}</p>
    </div>
  )
}

export default Notifications
