import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addIntegration } from 'features/website/websiteSlice'
import { setError } from 'features/error/errorSlice'
import { IoIosExit } from 'react-icons/io'
import { close } from 'features/apiKeys/apiKeysSlice'
const APIKeysModal = () => {
  const dispatch = useDispatch()
  const isOpenDefault = useSelector((state) => state.apiKeys.isOpen)

  const selectedWebsite = useSelector((state) => state.apiKeys.website)
  const selectedIntegration = useSelector((state) => state.apiKeys.integration)

  const websites = useSelector((state) => state.websites)
  const [isOpen, setIsOpen] = useState(isOpenDefault)
  useEffect(() => {
    setIsOpen(isOpenDefault)
  }, [isOpenDefault])
  //Inputs
  let hubspotTokenDefault
  let gravityKeyDefault
  let gravitySecretDefault
  let formidableKeyDefault
  let typeformKeyDefault
  let jotformKeyDefault
  const [gravityKey, setGravityKey] = useState(gravityKeyDefault)
  const [gravitySecret, setGravitySecret] = useState(gravitySecretDefault)
  const [hubspotToken, setHubspotToken] = useState(hubspotTokenDefault)
  const [formidableKey, setFormidableKey] = useState(formidableKeyDefault)
  const [typeformKey, setTypeformKey] = useState(typeformKeyDefault)
  const [jotformKey, setJotformKey] = useState(jotformKeyDefault)
  const addKey = (type, key, secret) => {
    if ((type === 'Gravity' && !key) || (type === 'Gravity' && !secret)) {
      return dispatch(setError('Key or secret is missing'))
    } else if (type === 'Formidable' && !key) {
      return dispatch(setError('Key is missing'))
    } else if (type === 'Typeform' && !key) {
      return dispatch(setError('Key is missing'))
    } else if (type === 'Jotform' && !key) {
      return dispatch(setError('Key is missing'))
    }

    try {
      dispatch(
        addIntegration({
          id: selectedWebsite,
          type,
          key,
          secret,
        }),
      )
    } catch (err) {
      dispatch(setError('Failed to add integration'))
    }
  }
  const closeModal = () => {
    dispatch(close())
  }
  const modalTypes = [
    {
      name: 'HubSpot',
      content: (
        <div className="flex flex-col items-center justify-center gap-5">
          <p className="w-full break-words ">{hubspotToken}</p>
          <a
            href={`https://app-eu1.hubspot.com/oauth/authorize?client_id=ef91cbe6-4f70-4df0-ba2f-b5293216fcb7&redirect_uri=${process.env.REACT_APP_BASE_URL}/website/oauth-callback&scope=forms&state=${selectedWebsite}`}
            className="flex-2 btn btn-primary"
          >
            {hubspotToken ? 'Reinstall HubSpot' : 'Install HubSpot'}
          </a>
        </div>
      ),
    },
    {
      name: 'Formidable',
      content: (
        <div className="flex flex-col items-center justify-center gap-5">
          <input
            type="text"
            placeholder="Formidable - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setFormidableKey(e.target.value)
            }}
            value={formidableKey}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Formidable', formidableKey)
            }}
          >
            {formidableKeyDefault ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
    {
      name: 'Typeform',
      content: (
        <div className="flex flex-col items-center justify-center gap-5">
          <input
            type="text"
            placeholder="Typeform - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setTypeformKey(e.target.value)
            }}
            value={typeformKey}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Typeform', typeformKey)
            }}
          >
            {typeformKeyDefault ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
    {
      name: 'Jotform',
      content: (
        <div className="flex flex-col items-center justify-center gap-5">
          <input
            type="text"
            placeholder="Jotform - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setJotformKey(e.target.value)
            }}
            value={jotformKey}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Jotform', jotformKey)
            }}
          >
            {jotformKeyDefault ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
    {
      name: 'Gravity',
      content: (
        <div className="flex flex-col gap-5">
          <input
            type="text"
            placeholder="Gravity - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setGravityKey(e.target.value)
            }}
            value={gravityKey}
          />
          <input
            type="text"
            placeholder="Gravity - secret"
            className="w-full input-bordered input"
            onChange={(e) => setGravitySecret(e.target.value)}
            value={gravitySecret}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Gravity', gravityKey, gravitySecret)
            }}
          >
            {gravityKeyDefault && gravitySecretDefault
              ? 'Reinstall'
              : 'Install'}
          </button>
        </div>
      ),
    },
  ]
  useEffect(() => {
    const website = websites.find((website) => website.id === selectedWebsite)
    const gravity = website?.integrations.find(
      (integration) => integration.type === 'Gravity',
    )
    const hubspot = website?.integrations.find(
      (integration) => integration.type === 'HubSpot',
    )

    const formidable = website?.integrations.find(
      (integration) => integration.type === 'Formidable',
    )
    const typeform = website?.integrations.find(
      (integration) => integration.type === 'Typeform',
    )
    const jotform = website?.integrations.find(
      (integration) => integration.type === 'Jotform',
    )

    gravityKeyDefault =
      gravity?.key && gravity.key.trim() !== '' ? gravity.key : null

    gravitySecretDefault =
      gravity?.secret && gravity.secret.trim() !== '' ? gravity.secret : null

    setHubspotToken(hubspot?.accessToken)

    formidableKeyDefault =
      formidable?.key && formidable.key.trim() !== '' ? formidable.key : null
    typeformKeyDefault =
      typeform?.key && typeform.key.trim() !== '' ? typeform.key : null
    jotformKeyDefault =
      jotform?.key && jotform.key.trim() !== '' ? jotform.key : null
  }, [selectedWebsite])

  return (
    <dialog id="my_modal_2" className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className=" modal-box">
        <IoIosExit
          size={30}
          className="mb-4 ml-auto cursor-pointer hover:text-slate-400"
          onClick={closeModal}
        />

        {modalTypes.find((type) => type.name === selectedIntegration)?.content}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={closeModal}>close</button>
      </form>
    </dialog>
  )
}

export default APIKeysModal
//  {modalTypes.find((type) => type.name === selectedIntegration).content}
