import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
//Hooks
import { useSelector } from 'react-redux'
import useRefreshToken from 'hooks/useRefreshToken'
import useLocalStorage from 'hooks/useLocalStorage'
const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const auth = useSelector((state) => state.auth)
  const [persist] = useLocalStorage('persist', false)

  useEffect(() => {
    let isMounted = true
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false)

    return () => (isMounted = false)
  }, [])

  return (
    <>
      {!persist ? (
        <Outlet />
      ) : isLoading ? (
        <div className="flex items-center justify-center w-full h-screen">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default PersistLogin
