import React from 'react'
import { Outlet } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const PUBLIC_KEY =
  'pk_test_51KBiIDGAzZQN5xaqG7ZBngUlyhNH6M1WsDlxmvKE2WMBno30xOUwLXqUo1cPYmi4lPxs6PuQELhkZLQ0s1J7VunQ00N7FFOluG'
const stripeTestPromise = loadStripe(PUBLIC_KEY)

const StripeContainer = () => {
  return (
    <Elements stripe={stripeTestPromise}>
      <Outlet />
    </Elements>
  )
}
export default StripeContainer
