import React from 'react'
import { Link } from 'react-router-dom'

//Hooks
import useLogout from 'hooks/useLogout'
import { useLocation } from 'react-router-dom'

const DashboardNav = () => {
  const location = useLocation()

  const logout = useLogout()

  return (
    <div
      className={`
        bg-gray-900
      hidden
        xl:fixed
        xl:flex
        top-[0]
        left-0
        xl:!translate-x-0 
        z-10
        px-4 
        h-full
        transition-transform 
        duration-[0.8s]
        ease-in-out 
        flex-col 
        justify-between 
        shadow-xl 
        py-10 
        w-[90vw] 
        max-w-[300px] 
        `}
    >
      <div className="flex flex-col justify-between h-full ">
        <ul className="mt-10 space-y-1 ">
          <li>
            <Link to="/forms">
              <button
                className={`w-full py-2 rounded-lg bg-gray-800 mb-2 hover:bg-opacity-80 ${
                  location.pathname === '/forms' && 'bg-primary text-slate-50'
                } `}
              >
                Forms
              </button>
            </Link>
          </li>
          <li>
            <Link to="/websites">
              <button
                className={`w-full py-2 rounded-lg bg-gray-800  hover:bg-opacity-80 ${
                  location.pathname === '/websites' &&
                  'bg-primary  text-slate-50 '
                } `}
              >
                Websites
              </button>
            </Link>
          </li>
        </ul>

        <button className="btn btn-primary" onClick={logout}>
          Sign Out
        </button>
      </div>
    </div>
  )
}

export default DashboardNav
