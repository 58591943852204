//Components
import Form from './components/Form'
import FilterForms from './components/FilterForms'
import CreatingForm from './components/CreatingForm'
//Hooks
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

//Utils
import { fetchWebsitesAsync } from 'features/website/websiteSlice'
import { fetchFormsAsync } from 'features/forms/formsSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { setError } from '../../../features/error/errorSlice'
import CreatingFormV2 from './components/CreatingFormV2'
const Forms = () => {
  const location = useLocation()
  const state = location.state

  const dispatch = useDispatch()
  const websites = useSelector((state) => state.websites)
  const forms = useSelector((state) => state.forms)

  // Getting all necesseary data
  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const resultAction = await dispatch(fetchWebsitesAsync())
        unwrapResult(resultAction)
      } catch (err) {
        dispatch(setError('Failed to fetch websites'))
      }
    }

    const fetchForms = async () => {
      try {
        const resultAction = await dispatch(fetchFormsAsync())
        unwrapResult(resultAction)
      } catch (err) {
        dispatch(setError('Failed to fetch forms'))
      }
    }

    fetchWebsites()
    fetchForms()
  }, [dispatch])
  //Adding a Form
  const websiteOptions = websites?.map((website) => ({
    value: website.id,
    label: website.name,
  }))

  //Filter
  const [filters, setFilters] = useState([])

  const changeFilter = (newFilter) => {
    filters.includes(newFilter)
      ? setFilters(filters.filter((filter) => filter !== newFilter))
      : setFilters([...filters, newFilter])
  }

  const [filteredForms, setFilteredForms] = useState(forms)
  const defaultFilterWebsite = state?.filter
    ? websiteOptions.find((option) => option.value === state.website)
    : null

  const [filterWebsite, setFilterWebsite] = useState(defaultFilterWebsite)
  useEffect(() => {
    setFilteredForms(forms)
  }, [forms])
  useEffect(() => {
    let filtered = forms
    filters.forEach((filter) => {
      if (filters.includes('deactivated')) {
        filtered = filtered.filter((form) => !form.active)
      }
      if (filters.includes('notifications')) {
        filtered = filtered.filter(
          (form) =>
            form.slackNotifications === false ||
            form.emailNotifications === false,
        )
      }
      if (filters.includes('failed')) {
        filtered = filtered.filter((form) => form.status === 'Failed')
      }
    })

    // Apply 'filterWebsite' if it's specified outside the filters array
    if (filterWebsite && filterWebsite.value) {
      filtered = filtered.filter((form) =>
        form.websites.includes(filterWebsite.value),
      )
    }

    setFilteredForms(filtered)
  }, [filters, forms, filterWebsite])
  const clearFilters = () => {
    setFilters([])
    setFilterWebsite(null)
  }
  return (
    <section>
      <CreatingFormV2 state={state} />
      {/* <CreatingForm state={state} /> */}
      <div className="divider"></div>
      <FilterForms
        changeFilter={changeFilter}
        setFilterWebsite={setFilterWebsite}
        website={filterWebsite}
        filter={filters}
        clearFilters={clearFilters}
      />
      <div className="divider"></div>
      <div className="w-full ">
        {filteredForms.map((form, index) => (
          <Form key={`${form.id} - ${index}`} form={form} />
        ))}
      </div>
    </section>
  )
}

export default Forms
