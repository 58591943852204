import React, { useState, useEffect } from 'react'
//Hooks
import useLogout from 'hooks/useLogout'
import useRefreshToken from 'hooks/useRefreshToken'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { useDispatch, useSelector } from 'react-redux'
//React icons
import { FaEye, FaRegEyeSlash } from 'react-icons/fa'
//Schemas
import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  passwordSchema,
} from 'schemas/validation'
//Utils
import { setError } from 'features/error/errorSlice'
//----------------------
const CHANGE_URL = 'user/update-user'
//----------------------
function UpdateInfo() {
  const dispatch = useDispatch()
  const logout = useLogout()
  const refresh = useRefreshToken()
  const axiosPrivate = useAxiosPrivate()
  const auth = useSelector((state) => state.auth)

  const [err, setErr] = useState(null)

  const [email, setEmail] = useState(auth.email)
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [firstName, setFirstName] = useState(auth.firstName)
  const [validFirstName, setValidFirstName] = useState(false)
  const [firstNameFocus, setFirstNameFocus] = useState(false)

  const [lastName, setLastName] = useState(auth.lastName)
  const [validLastName, setValidLastName] = useState(false)
  const [lastNameFocus, setLastNameFocus] = useState(false)

  const [oldPassword, setOldPassword] = useState('')
  const [validOldPassword, setValidOldPassword] = useState(false)
  const [oldPasswordError, setOldPasswordError] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [validNewPassword, setValidNewPassword] = useState(false)
  const [newPasswordFocus, setNewPasswordFocus] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)
  const [showMatch, setShowMatch] = useState(false)

  //Validating inputs
  useEffect(() => {
    setValidEmail(!emailSchema.validate(email).error)
    setValidFirstName(!firstNameSchema.validate(firstName).error)
    setValidLastName(!lastNameSchema.validate(lastName).error)
    setValidOldPassword(!passwordSchema.validate(oldPassword).error)
    setValidNewPassword(!passwordSchema.validate(newPassword).error)
    setValidMatch(newPassword === matchPwd)
  }, [newPassword, matchPwd, oldPassword, email, firstName, lastName])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Perform update credentials logic here
    try {
      switch (true) {
        case oldPassword === '':
          setOldPasswordError('Old password is required')
          setTimeout(() => {
            setOldPasswordError('')
          }, 3000)
          return
        case !validOldPassword:
          setErr('Invalid old password format')
          return
        case !validNewPassword && newPassword !== '':
          setErr('Invalid new password format')
          return
        case !validFirstName:
          setErr('Invalid first name format')
          return
        case !validLastName:
          setErr('Invalid last name format')
          return
        case !validEmail:
          setErr('Invalid email format')
          return
        case !validMatch && matchPwd !== '':
          setErr('Passwords do not match')
          return
        default:
      }

      const response = await axiosPrivate.put(CHANGE_URL, {
        email,
        password: oldPassword,
        newPassword: newPassword,
        newPasswordConfirmation: matchPwd,
        firstName,
        lastName,
      })

      if (response.status === 200) {
        console.log(response.data.message)
      }
      setOldPassword('')
      setNewPassword('')
      setMatchPwd('')
      setErr('')
      refresh()
    } catch (error) {
      if (!error?.response) {
        console.error('Server shut down')
      } else if (error.response.status === 401) {
        setErr(error.response.data.message)
      } else {
        dispatch(setError('Failed to update the user'))
        console.error(error?.message)
      }
    }
  }
  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await axiosPrivate.get('user/delete-user')
      logout()
    } catch (err) {
      dispatch(setError('Failed to delete the user'))
    }
  }

  return (
    <form onSubmit={handleSubmit} className="w-full sm:max-w-[500px] ">
      <div className="h-[32px]">
        <p className={`font-bold text-red-400 ${err ? '' : 'hidden'}`}>{err}</p>
      </div>
      <div className="flow-root ">
        <dl className="my-3 text-sm divide-y divide-gray-100 ">
          <div className="grid grid-cols-1 gap-1 py-3 xs:grid-cols-3 sm:gap-4">
            <dt className="flex items-center font-medium">
              <label>Email:</label>
            </dt>
            <dd className="relative xs:col-span-2">
              <input
                id="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                className="w-full px-3 py-1 shadow-sm input input-bordered"
                onFocus={() => {
                  setEmailFocus(true)
                }}
                onBlur={() => {
                  setEmailFocus(false)
                }}
                aria-describedby="email-note"
                aria-invalid={validEmail ? 'false' : 'true'}
              />

              <div
                className={`absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50 ${
                  emailFocus && email && !validEmail ? '' : 'hidden'
                }`}
              >
                <p id="email-note">
                  Must start with onre or more alphanumeric characters, dots,
                  percent signs, plus signs, or hyphens.
                  <br />
                  Followed by an at sign @
                  <br />
                  Followed by one or more alphanumeric characters, dots, or
                  hyphens.
                  <br />
                  Followed by a dot.
                  <br />
                  End it need's to end with two or more letters.
                </p>
              </div>
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 xs:grid-cols-3 sm:gap-4">
            <dt className="flex items-center font-medium">
              <label>First Name:</label>
            </dt>
            <dd className="relative xs:col-span-2">
              <input
                id="FirstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoComplete="new-password"
                className="w-full px-3 py-1 shadow-sm input input-bordered"
                aria-invalid={validFirstName ? 'false' : 'true'}
                aria-describedby="firstname-note"
                onFocus={() => setFirstNameFocus(true)}
                onBlur={() => setFirstNameFocus(false)}
              />
              <div
                className={`absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50 ${
                  firstNameFocus && firstName && !validFirstName ? '' : 'hidden'
                }`}
              >
                <p id="firstname-note">
                  1 to 24 characters.
                  <br />
                  Numbers, spaces, underscores, hyphens and symbols are not
                  allowed.
                </p>
              </div>
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 xs:grid-cols-3 sm:gap-4">
            <dt className="flex items-center font-medium">
              <label>Last Name:</label>
            </dt>
            <dd className="relative xs:col-span-2">
              <input
                id="LastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                autoComplete="new-password"
                className="w-full px-3 py-1 shadow-sm input input-bordered"
                aria-invalid={validLastName ? 'false' : 'true'}
                aria-describedby="lastname-note"
                onFocus={() => setLastNameFocus(true)}
                onBlur={() => setLastNameFocus(false)}
              />
              <div
                className={`absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50 ${
                  lastNameFocus && lastName && !validLastName ? '' : 'hidden'
                }`}
              >
                <p id="lastname-note">
                  1 to 24 characters.
                  <br />
                  Numbers, spaces, underscores, hyphens and symbols are not
                  allowed.
                </p>
              </div>
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 xs:grid-cols-3 sm:gap-4">
            <dt className="flex items-center font-medium ">
              <label>Old Password:</label>
            </dt>
            <dd className="relative xs:col-span-2">
              <input
                id="OldPassword"
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                autoComplete="off"
                aria-invalid={validOldPassword ? 'false' : 'true'}
                aria-describedby="oldpwd-note"
                className="block w-full py-1 pl-3 pr-10 shadow-sm input input-bordered"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => {
                  setShowOldPassword(!showOldPassword)
                }}
              >
                {showOldPassword ? <FaRegEyeSlash /> : <FaEye />}
              </button>
              {oldPasswordError && (
                <div className="absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50">
                  <p>{oldPasswordError}</p>
                </div>
              )}
              <p
                id="oldpwd-note"
                className={`${
                  oldPassword && !validOldPassword ? '' : 'hidden'
                } absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50`}
              >
                8 to 24 characters. <br />
                Must include uppercase and lowercase letters, a number and a
                special character. Allowed special characters:
                <span aria-label="exclamation mark">!</span>
                <span aria-label="at symbol">@</span>{' '}
                <span aria-label="hashtag">#</span>
                <span aria-label="dollar sign">$</span>{' '}
                <span aria-label="percent">%</span>
                <br />
              </p>
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 xs:grid-cols-3 sm:gap-4">
            <dt className="flex items-center font-medium">
              <label>New Password:</label>
            </dt>
            <dd className="relative xs:col-span-2">
              <input
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="Password"
                autoComplete="new-password"
                className="block w-full py-1 pl-3 pr-10 shadow-sm input input-bordered"
                onBlur={() => setNewPasswordFocus(false)}
                onFocus={() => setNewPasswordFocus(true)}
                aria-describedby="newpwd-note"
                aria-invalid={validNewPassword ? 'false' : 'true'}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaRegEyeSlash /> : <FaEye />}
              </button>

              <div
                className={`absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50 ${
                  newPasswordFocus && !validNewPassword ? '' : 'hidden'
                }`}
              >
                <p id="newpwd-note">
                  8 to 24 characters. <br />
                  Must include uppercase and lowercase letters, a number and a
                  special character. Allowed special characters:
                  <span aria-label="exclamation mark">!</span>
                  <span aria-label="at symbol">@</span>{' '}
                  <span aria-label="hashtag">#</span>
                  <span aria-label="dollar sign">$</span>{' '}
                  <span aria-label="percent">%</span>
                  <br />
                </p>
              </div>
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 xs:grid-cols-3 sm:gap-4">
            <dt className="flex items-center font-medium">
              <label>Password Confirmation:</label>
            </dt>
            <dd className="relative xs:col-span-2">
              <input
                type={showMatch ? 'text' : 'password'}
                value={matchPwd}
                id="PasswordConfirmation"
                onChange={(e) => setMatchPwd(e.target.value)}
                autoComplete="new-password"
                className="block w-full py-1 pl-3 pr-10 shadow-sm input input-bordered"
                onBlur={() => setMatchFocus(false)}
                onFocus={() => setMatchFocus(true)}
                aria-describedby="confirm-note"
                aria-disabled={validMatch ? 'false' : 'true'}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => {
                  setShowMatch(!showMatch)
                }}
              >
                {showMatch ? <FaRegEyeSlash /> : <FaEye />}
              </button>

              <div
                className={`absolute left-0 z-20 w-full p-2 bg-black rounded-lg text-slate-50 ${
                  matchFocus && !validMatch ? '' : 'hidden'
                }`}
              >
                <p id="confirm-note">
                  Must match the first password input field.
                </p>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div className="flex flex-wrap items-center gap-2 justify-normal">
        <button
          className="text-white bg-blue-500 hover:bg-blue-500/80 btn"
          type="submit"
        >
          Save
        </button>
        <button
          className="text-white bg-red-500 btn hover:bg-red-500/80"
          onClick={handleDelete}
        >
          Delete User
        </button>
      </div>
    </form>
  )
}

export default UpdateInfo
