import React, { useState, useEffect } from 'react'
//Hooks
import axios from 'api/axios'
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import useRefreshToken from 'hooks/useRefreshToken'
//Components
import Loading from 'components/Loading'
//Utils
import { setError } from 'features/error/errorSlice'
const PRICING_PLANS = [
  {
    id: '123e4567-e89b-12d3-a456-426614174000',
    name: 'Basic',
    description: 'Basic plan has ...',
    pricingId: 'price_1OMpcHGAzZQN5xaqZ6Wa5tKM',
  },
  {
    id: '987e6543-e21b-34d5-b654-426614174001',
    name: 'Premium',
    description: 'Premium plan has ... ',
    pricingId: 'price_1OMpcaGAzZQN5xaqofhuNPjP',
  },
  {
    id: '567e8901-e23b-45d6-c789-426614174002',
    name: 'Pro',
    description: 'Pro plan has ...',
    pricingId: 'price_1OMpdWGAzZQN5xaqhzxyOSev',
  },
]

const ChangePlan = () => {
  const refresh = useRefreshToken()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [pricingPlan, setPricingPlan] = useState(
    PRICING_PLANS.find((plan) => plan.id === auth.plan),
  )
  const [plansList, setPlansList] = useState([])
  const axiosPrivate = useAxiosPrivate()

  //Updating customer's plan
  const handleUpdate = async () => {
    try {
      setLoading(true)
      await axiosPrivate.post('/user/change-plan', {
        email: auth.email,
        newPlan: pricingPlan.id,
      })
      refresh()
    } catch (err) {
      dispatch(setError('Failed to update the plan.'))

      console.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }
  //Canceling customer's plan
  const handleCancel = async () => {
    try {
      setLoading(true)
      await axiosPrivate.post('/user/cancel-plan')
      refresh()
      setPricingPlan(null)
    } catch (err) {
      dispatch(setError('Error in canceling the plan.'))
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const getPlans = async () => {
      try {
        setLoading(true)
        const response = await axios.get('user/retrieve-plans')
        setLoading(false)
        setPlansList(response.data)
      } catch (err) {
        dispatch(setError('Error in retrieving plans.'))
      }
    }
    getPlans()
  }, [])
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col items-center w-full jusify-center">
          <div className="flex flex-col items-center justify-around w-full gap-2  xs:flex-row max-w-[700px]">
            {plansList?.map((plan, index) => (
              <div
                key={index}
                className={`${
                  PRICING_PLANS[index].id === pricingPlan.id
                    ? 'border-primary'
                    : ''
                } card border sm:max-w-[180px] w-[95%] max-w-[250px]`}
              >
                <div className="card-body ">
                  {' '}
                  <h2 className="text-xl font-bold">{plan.name}</h2>
                  <p className="text-lg">{plan.price / 100}$/month</p>
                  <button
                    onClick={() => {
                      setPricingPlan(PRICING_PLANS[index])
                    }}
                    className={`btn ${
                      PRICING_PLANS[index] === pricingPlan ? 'btn-primary' : ''
                    }`}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-center gap-2">
            <button className="btn" onClick={handleUpdate}>
              Update Plan
            </button>
            <button className="btn" onClick={handleCancel}>
              Cancel Plan
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ChangePlan
