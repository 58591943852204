import React, { useState, useEffect, useRef } from 'react'
//React icons
import { GiCheckMark } from 'react-icons/gi'
import { BiError } from 'react-icons/bi'
import styles from '../register.module.css'
//Hooks
import { useDispatch } from 'react-redux'
//Utils
import { updateUserInfo } from 'features/userInfo/userInfoSlice'
//Schema
import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  passwordSchema,
} from 'schemas/validation.js'

const UserInfo = (props) => {
  const dispatch = useDispatch()

  const firstNameRef = useRef()
  const [firstName, setFirstName] = useState('')
  const [validFirst, setValidFirst] = useState(false)
  const [firstFocus, setFirstFocus] = useState(false)

  const [lastName, setLastName] = useState('')
  const [validLast, setValidLast] = useState(false)
  const [lastFocus, setLastFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [pwd, setPwd] = useState('')
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  //Setting the focus on User input field
  useEffect(() => {
    firstNameRef.current.focus()
  }, [])

  //Validating inputs
  useEffect(() => {
    setValidFirst(!firstNameSchema.validate(firstName).error)
    setValidLast(!lastNameSchema.validate(lastName).error)
    setValidEmail(!emailSchema.validate(email).error)
    setValidPwd(!passwordSchema.validate(pwd).error)
    setValidMatch(pwd === matchPwd)
  }, [firstName, lastName, email, pwd, matchPwd])

  useEffect(() => {
    dispatch(updateUserInfo({ firstName, lastName, email, pwd, matchPwd }))
  }, [firstName, lastName, email, pwd, matchPwd])

  return (
    <form className={props.page !== 1 ? 'hidden' : ''}>
      <div className="grid grid-cols-1 gap-4 mb-10 md:grid-cols-2">
        <div className="relative ">
          <label
            htmlFor="FirstName"
            className="flex items-center gap-2 text-sm font-medium "
          >
            First Name
            <span className={validFirst ? 'block' : 'hidden'}>
              <GiCheckMark className="text-green-500" />
            </span>
            <span className={validFirst || !firstName ? 'hidden' : 'block'}>
              <BiError className="text-red-500" />
            </span>
          </label>

          <input
            type="text"
            id="FirstName"
            autoComplete="off"
            required
            aria-invalid={validFirst ? 'false' : 'true'}
            aria-describedby="first-note"
            onChange={(e) => {
              setFirstName(e.target.value)
            }}
            ref={firstNameRef}
            onFocus={() => setFirstFocus(true)}
            onBlur={() => setFirstFocus(false)}
            className="w-full max-w-xs input input-bordered input-primary"
          />

          <p
            id="first-note"
            className={
              firstFocus && firstName && !validFirst
                ? 'absolute bg-black text-slate-50 p-2 rounded-lg z-10'
                : 'hidden'
            }
          >
            1 to 24 characters.
            <br />
            Numbers, spaces, underscores, hyphens and symbols are not allowed.
          </p>
        </div>

        <div className="relative ">
          <label
            htmlFor="LastName"
            className="flex items-center gap-2 text-sm font-medium "
          >
            Last Name
            <span className={validLast ? 'block' : 'hidden'}>
              <GiCheckMark className="text-green-500" />
            </span>
            <span className={validLast || !lastName ? 'hidden' : 'block'}>
              <BiError className="text-red-500" />
            </span>
          </label>

          <input
            type="text"
            id="LastName"
            autoComplete="off"
            required
            aria-invalid={validLast ? 'false' : 'true'}
            aria-describedby="last-note"
            onChange={(e) => {
              setLastName(e.target.value)
            }}
            onFocus={() => {
              setLastFocus(true)
            }}
            onBlur={() => {
              setLastFocus(false)
            }}
            className="w-full max-w-xs input input-bordered input-primary"
          />
          <p
            id="last-note"
            className={
              lastFocus && lastName && !validLast
                ? 'absolute bg-black text-slate-50 p-2 rounded-lg z-10'
                : 'hidden'
            }
          >
            1 to 24 characters.
            <br />
            Numbers, spaces, underscores, hyphens and symbols are not allowed.
          </p>
        </div>

        <div className="relative ">
          <label
            htmlFor="Email"
            className="flex items-center gap-2 text-sm font-medium "
          >
            {' '}
            Email{' '}
            <span className={validEmail ? 'block' : 'hidden'}>
              <GiCheckMark className="text-green-500" />
            </span>
            <span className={validEmail || !email ? 'hidden' : 'block'}>
              <BiError className="text-red-500" />
            </span>
          </label>

          <input
            type="email"
            id="Email"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            onFocus={() => {
              setEmailFocus(true)
            }}
            onBlur={() => {
              setEmailFocus(false)
            }}
            aria-invalid={validEmail ? 'false' : 'true'}
            aria-describedby="email-note"
            className="w-full max-w-xs input input-bordered input-primary"
          />
          <p
            id="email-note"
            className={
              emailFocus && email && !validEmail
                ? 'absolute bg-black text-slate-50 p-2 rounded-lg z-10'
                : 'hidden'
            }
          >
            Must start with one or more alphanumeric characters, dots, percent
            signs, plus signs, or hyphens.
            <br />
            Followed by an at sign @
            <br />
            Followed by one or more alphanumeric characters, dots, or hyphens.
            <br />
            Followed by a dot.
            <br />
            End it need's to end with two or more letters.
          </p>
        </div>

        <div className="relative ">
          <label
            htmlFor="Password"
            className="flex items-center gap-2 text-sm font-medium "
          >
            {' '}
            Password{' '}
            <span className={validPwd ? 'block' : 'hidden'}>
              <GiCheckMark className="text-green-500" />
            </span>
            <span className={validPwd || !pwd ? 'hidden' : 'block'}>
              <BiError className="text-red-500" />
            </span>
          </label>

          <input
            autoComplete="off"
            type="password"
            id="Password"
            onChange={(e) => setPwd(e.target.value)}
            required
            aria-invalid={validPwd ? 'false' : 'true'}
            aria-describedby="pwdnote"
            onFocus={() => setPwdFocus(true)}
            onBlur={() => setPwdFocus(false)}
            className="w-full max-w-xs input input-bordered input-primary"
          />
          <p
            id="pwdnote"
            className={
              pwdFocus && !validPwd
                ? 'absolute bg-black text-slate-50 p-2 rounded-lg z-10'
                : 'hidden'
            }
          >
            8 to 24 characters. <br />
            Must include uppercase and lowercase letters, a number and a special
            character. Allowed special characters:
            <span aria-label="exclamation mark">!</span>
            <span aria-label="at symbol">@</span>{' '}
            <span aria-label="hashtag">#</span>
            <span aria-label="dollar sign">$</span>{' '}
            <span aria-label="percent">%</span>
            <br />
          </p>
        </div>

        <div className="relative">
          <label
            htmlFor="PasswordConfirmation"
            className="flex items-center gap-2 text-sm font-medium "
          >
            Password Confirmation
            <span className={validMatch && matchPwd ? 'block' : 'hidden'}>
              <GiCheckMark className="text-green-500" />
            </span>
            <span className={validMatch || !matchPwd ? 'hidden' : 'block'}>
              <BiError className="text-red-500" />
            </span>
          </label>

          <input
            autoComplete="off"
            type="password"
            id="PasswordConfirmation"
            onChange={(e) => setMatchPwd(e.target.value)}
            required
            aria-invalid={validMatch ? 'false' : 'true'}
            aria-describedby="confirmnote"
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}
            className="w-full max-w-xs input input-bordered input-primary"
          />
          <p
            id="confirmnote"
            className={
              matchFocus && !validMatch
                ? 'absolute bg-black text-slate-50 p-2 rounded-lg z-10'
                : 'hidden'
            }
          >
            Must match the first password input field.
          </p>
        </div>
      </div>
      {/* <div className="col-span-6">
      <label htmlFor="MarketingAccept" className="flex gap-4">
        <input
          type="checkbox"
          id="MarketingAccept"
          name="marketing_accept"
          className="w-5 h-5 bg-white border-gray-200 rounded-md shadow-sm"
        />

        <span className="text-sm text-gray-700">
          I want to receive emails about events, product updates and
          company announcements.
        </span>
      </label>
    </div>

    <div className="col-span-6">
      <p className="text-sm text-gray-500">
        By creating an account, you agree to our
        <a href="#" className="text-gray-700 underline">
          {" "}
          terms and conditions{" "}
        </a>
        and
        <a href="#" className="text-gray-700 underline">
          privacy policy
        </a>
        .
      </p>
    </div> */}
    </form>
  )
}

export default UserInfo
