import React from 'react'

//Components
import Hero from './components/Hero'
import Advantages from './components/Advantages'
import CTA from './components/CTA'
import Integrations from './components/Integrations'
const Home = () => {
  return (
    <div>
      <Hero />

      <Advantages />
      <Integrations />

      <CTA />
    </div>
  )
}

export default Home
