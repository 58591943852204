const Joi = require('joi')

const firstNameSchema = Joi.string()
  .regex(/^[A-Za-z]+$/)
  .min(1)
  .max(24)
  .required()
const lastNameSchema = Joi.string()
  .regex(/^[A-Za-z]+$/)
  .min(3)
  .max(30)
  .required()
const emailSchema = Joi.string()
  .email({ minDomainSegments: 2, tlds: false })
  .required()
const passwordSchema = Joi.string()
  .pattern(
    new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$'),
  )
  .required()

module.exports = {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  passwordSchema,
}
