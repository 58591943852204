import React from 'react'
//Styles
import styles from './style.module.css'
const Documents = () => {
  return (
    <div>
      <div className={styles.section}>
        <h3>
          How to Add a Website and Configure API Keys/Tokens to your Dashboard
        </h3>
        <h2>Adding a Website</h2>
        <p>
          Start by providing a unique name that easily identifies your website.
          Locate the "Website name" field and input your chosen name. You have
          the freedom to choose any name that suits your preference.
        </p>
        <h2>Adding API Keys/Tokens Associated with the Website</h2>
        <p>
          Each form type associated with a particular website requires its
          unique API key or token. The process of adding your specific key/token
          differs based on each form type. Here's how to proceed:
        </p>
        <ul>
          <li>
            Go to the "Website" tab on your Dashboard. Here, you'll find an
            "Integrations" dropdown menu, which lets you peruse through your
            form types and check the status of installed API keys.
          </li>
          <li>
            On the list, you will notice a circle icon next to each form type --
            a green color indicates that the key/token is installed, while red
            signifies the contrary.
          </li>
          <li>
            Once you select your preferred form type, click on the "Connect"
            button in the dropdown menu. This action triggers a popup window,
            prompting you to input your API key or token.
          </li>
        </ul>
        <h2>Adding or Editing a Key/Token in a Different Way</h2>
        <p>
          Is your API key or token missing or requires amendment? Navigate to
          the "User-Settings" page, followed by the "Integrations" tab. After
          this:
        </p>
        <ul>
          <li>
            Here you can select the specific website, which will list out all
            installed or uninstalled API keys/tokens. This feature allows you to
            manage and keep track of all your API keys/tokens under one roof.
          </li>
        </ul>
      </div>
      <div className={styles.section}>
        <h3>How to add a form</h3>
        <h2>Step 1: Selecting Form Type</h2>
        <p>
          To begin, select your preferred form type from the available options.
          We accommodate an array of versatile options such as Gravity, HubSpot,
          Formidable, JotForm, and Typeform.
        </p>
        <h2>Step 2: Associating the Form to a Website</h2>
        <p>
          Look for the dropdown menu and select the target website that you
          intend to link with your form. Our application will then utilize its
          API key to enable form testing. Once the form is associated with the
          website, you can subsequently filter the form on the dashboard by the
          assigned website.
        </p>
        <h2>Step 3: Enter Form URL and Form ID</h2>
        <p>
          In the "Form URL" field, input the URL of the webpage where the form
          resides. Following that, in the "Form ID" tab, input the form's unique
          ID (if you need guidance on how to locate the Form ID, see the
          instructions provided below). Locating Form ID: Dependent on the form
          type, the instructions to locate the Form ID vary:
        </p>
        <ul>
          <li>
            HubSpot: Check the URL of the form when accessed through
            HubSpot: https://app.hubspot.com/submissions/9458836/form/string_of_characters/.
            Here, the Form ID comprises of the string of characters at the end
            of the URL.
          </li>
          <li>
            Typeform: In your Typeform dashboard, locate your form’s URL,
            similar to https://admin.typeform.com/form/string_of_characters/,
            where the Form ID is the string of characters.
          </li>
          <li>
            Gravity & Formidable: For both Gravity and Formidable forms located
            in WordPress, find the corresponding dashboard and navigate to the
            list of forms tab. The Form ID for the required form will be listed
            alongside.
          </li>
          <li>
            JotForm: Access the targeted form in your JotForm dashboard and
            select the 'edit' option. The Form ID will be part of the shown URL,
            such as https://eu.jotform.com/build/form_id.
          </li>
        </ul>
        <p>
          Our functional and intuitive dashboard allows simplified management
          and testing of your forms to ensure optimal user interaction and
          maximum engagement.
        </p>
      </div>
      <div className={styles.section}>
        <h3>Managing Notifications and Controlling Form Testing</h3>
        <h2>Website-Based Notifications and Testing Toggles</h2>
        <p>
          In the '/websites' page, a set of toggles are housed under each
          website tab designed to manage form testing and notifications.
        </p>
        <ul>
          <li>
            Notification Toggle: By switching on this toggle, you enable
            notifications for all forms linked to the specific website. The
            notifications will be sent through email and Slack (if integrated).
            Switching it off will cease the notification transmission.
          </li>
          <li>
            Testing Toggle: The testing toggle controls whether the forms
            associated with the website are added to or removed from the testing
            queue. Switching it on adds all linked forms to the testing lineup,
            whereas turning it off removes them.
          </li>
        </ul>
        <h2>Form-Specific Notifications and Testing Toggles</h2>
        <p>
          Additionally, in the '/forms' page, each form is endowed with specific
          toggles to manage notifications and form testing.
        </p>
        <ul>
          <li>
            Notification Toggles: For every form, there are separate toggles for
            each type of notification. Switching these toggles on or off will
            regulate notifications sent to email or Slack.
          </li>
          <li>
            Testing Toggle: Each form has a unique toggle to control its
            addition or removal from the testing queue. Simply embark on adding
            or discontinuing form testing by switching this toggle.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Documents
