import React from 'react'

//Images
import AgileTesting from 'assets/Home/Agile testing.jpg'
import Customization from 'assets/Home/Customization.jpg'
import PowerfulInsight from 'assets/Home/Powerful Insight.jpg'
const Advantages = () => {
  return (
    <div className="!text-left">
      <section className="mx-auto max-w-[600px]">
        <h3 className="text-primary">ADVANTAGES</h3>
        <h2>Now you can automate website form testing</h2>
        <p>
          Formibly regularly tests your HubSpot, Formidable, and other forms so
          you don't have to. Our automated testing bot verifies submissions,
          redirects, captcha decoding, and email notifications.
        </p>
      </section>
      <section>
        <div className="max-w-screen-xl mx-auto sm:py-12 lg:py-16">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
              <img
                alt="Human and robot shaking hands"
                src={AgileTesting}
                className="absolute inset-0 object-cover w-full h-full"
              />
            </div>

            <div className="lg:py-24">
              <h2>Agile Testing Capabilities</h2>

              <p className="my-5  max-w-[600px]">
                The Formibly app automatically fills out and submits your forms
                using preset values to test performance.
              </p>

              <ul className="space-y-4 text-left ">
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5 text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Form Submissions</span>
                </li>
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Redirects</span>
                </li>
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Real-time and batch alerts</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-screen-xl py-8 mx-auto sm:py-12 lg:py-16">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full">
              <img
                alt="PC showing customization screen"
                src={Customization}
                className="absolute inset-0 object-cover w-full h-full"
              />
            </div>

            <div className="lg:py-24">
              <h2>Customization & Control</h2>

              <p className="my-5 max-w-[600px]">
                The Formibly platform can be tailored to fit your specific
                needs.
              </p>

              <ul className="space-y-4 text-left ">
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5 text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Dashboard View</span>
                </li>
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Adjustable testing cadence</span>
                </li>
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Flexible alert settings</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-screen-xl py-8 mx-auto sm:py-12 lg:py-16">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
              <img
                alt="Piece of paper showing a chart"
                src={PowerfulInsight}
                className="absolute inset-0 object-cover w-full h-full"
              />
            </div>

            <div className="lg:py-24">
              <h2>Powerful Insights</h2>

              <p className="my-5 max-w-[600px]">
                Get valuable data about form and website performance
              </p>

              <ul className="space-y-4 text-left ">
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Failed form alerts</span>
                </li>
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Success rate reports</span>
                </li>
                <li className="flex items-center space-x-3 rtl:space-x-reverse">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5  text-primary-color dark:text-primary-color"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                  <span>Actionable remediation</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Advantages
