import React, { useState, useEffect } from 'react'
//Hooks
import useInput from 'hooks/useInput'
import { useDispatch } from 'react-redux'
import axios from '../api/axios'
//Utils
import { setError } from 'features/error/errorSlice'
//Schemas
import { emailSchema, passwordSchema } from 'schemas/validation'

const ResetPassword = () => {
  const dispatch = useDispatch()

  const [email, emailAtribs] = useInput('email', ' ')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)

  const [confirmPassword, setConfirmPassword] = useState('')
  const [validConfirmPassword, setValidConfirmPassword] = useState(false)
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false)

  //Validating inputs
  useEffect(() => {
    setValidEmail(!emailSchema.validate(email).error)
    setValidPassword(!passwordSchema.validate(password).error)
    setValidConfirmPassword(password === confirmPassword)
  }, [email, password, confirmPassword])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (!validEmail || !validPassword || !validConfirmPassword)
        return dispatch(setError('Invalid input'))
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('token')
      await axios.post('/user/reset-password', {
        email,
        token,
        password,
      })
    } catch (error) {
      dispatch(setError('Something went wrong, please try again later'))
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-4 px-4 mx-auto py-[200px] sm:px-6 lg:px-8">
      <h1 className="text-xl font-semibold">Reset Your Password</h1>
      <p className="max-w-[600px] ">
        We're here to help you reset your password. To ensure smooth
        proceedings, we ask you to provide the following information.
      </p>
      <ul className="list-disc max-w-[600px]">
        <li>Please enter your email address associated with this account.</li>
        <li>Input the Password Reset Token sent to your email.</li>
        <li>
          Choose your new password. Remember, a strong password that includes a
          combination of letters, numbers, and special characters adds an extra
          layer of security to your account.
        </li>
        <li>
          Confirm your new password by re-entering it in the "Confirm New
          Password" field.
        </li>
      </ul>
      <form className="w-full max-w-[400px] mx-auto mb-0 flex flex-col gap-2 items-start justify-center ">
        <div className="w-full">
          <label htmlFor="email" className="sr-only">
            Email
          </label>

          <div className="relative w-full">
            <input
              type="email"
              className="w-full p-4 text-sm border-gray-200 rounded-lg shadow-sm pe-12"
              placeholder="Enter email"
              id="email"
              {...emailAtribs}
              aria-invalid={!validEmail && email && emailFocus}
              aria-describedby="email-note"
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
              required
            />

            <span className="absolute inset-y-0 grid px-4 end-0 place-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </span>
            <p
              id="email-note"
              className={`bg-black w-full text-slate-50 p-2 z-10 absolute rounded-lg ${
                !validEmail && email && emailFocus ? '' : 'hidden'
              }`}
            >
              Must start with onre or more alphanumeric characters, dots,
              percent signs, plus signs, or hyphens.
              <br />
              Followed by an at sign @
              <br />
              Followed by one or more alphanumeric characters, dots, or hyphens.
              <br />
              Followed by a dot.
              <br />
              End it need's to end with two or more letters.
            </p>
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="new-password" className="sr-only">
            New Password
          </label>
          <label htmlFor="confirm-new-password" className="sr-only">
            Confirm New Password
          </label>
          <div className="relative">
            <input
              type="password"
              className="w-full p-4 text-sm border-gray-200 rounded-lg shadow-sm pe-12"
              placeholder="New Password"
              id="new-password"
              aria-invalid={!validPassword && password && passwordFocus}
              aria-describedby="password-note"
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
              autoComplete="false"
              required
            />
            <p
              id="password-note"
              className={`bg-black w-full text-slate-50 p-2 z-10 absolute rounded-lg ${
                !validPassword && password && passwordFocus ? '' : 'hidden'
              }`}
            >
              8 to 24 characters. <br />
              Must include uppercase and lowercase letters, a number and a
              special character. Allowed special characters:
              <span aria-label="exclamation mark">!</span>
              <span aria-label="at symbol">@</span>{' '}
              <span aria-label="hashtag">#</span>
              <span aria-label="dollar sign">$</span>{' '}
              <span aria-label="percent">%</span>
              <br />
            </p>
          </div>

          <div className="relative mt-2">
            <input
              type="password"
              className="w-full p-4 text-sm border-gray-200 rounded-lg shadow-sm pe-12"
              placeholder="Confirm New Password"
              id="confirm-new-password"
              aria-invalid={validConfirmPassword ? false : true}
              aria-describedby="confirmnote"
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={() => setConfirmPasswordFocus(true)}
              onBlur={() => setConfirmPasswordFocus(false)}
              autoComplete="false"
              required
            />
            <p
              id="confirmnote"
              className={
                confirmPasswordFocus && !validConfirmPassword
                  ? 'bg-black w-full text-slate-50 p-2 z-10 absolute rounded-lg'
                  : 'hidden'
              }
            >
              Must match the first password input field.
            </p>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          Reset Password
        </button>
      </form>
    </div>
  )
}
export default ResetPassword
