import React from 'react'
import { useNavigate } from 'react-router-dom'
//Images
import Logo from 'assets/Formibly-white-logo.png'
const Missing = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  return (
    <section className="px-4 flex  flex-col items-center justify-center h-[100vh]">
      <img src={Logo} alt="Formibly logo" className="w-[90%] max-w-[400px]" />

      <p className="text-lg font-semibold">
        You do not have access to the requested page or it is non-existent
      </p>
      <button onClick={goBack} className="mt-5 btn btn-primary">
        Go Back
      </button>
    </section>
  )
}

export default Missing
