import React from 'react'
import { Link } from 'react-router-dom'
const CTA = () => {
  return (
    <section className="overflow-hidden bg-top bg-no-repeat bg-cover cta">
      <div className="p-8 bg-black/25 md:p-12 lg:px-16 lg:py-24">
        <div className="text-left ltr:sm:text-left rtl:sm:text-right">
          <h2 className="text-2xl font-bold text-white sm:text-3xl md:text-5xl">
            Formibly
          </h2>

          <p className="hidden max-w-lg text-white/90 md:mt-6 md:block md:text-lg md:leading-relaxed">
            We’re still building. Subscribe for updates and 20% off when we
            launch. No spam, we promise!
          </p>

          <div className="mt-4 sm:mt-8">
            <Link to="/register">
              <button className="btn btn-primary">Sign up for beta</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
