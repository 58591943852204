//Compoents
import { RiDeleteBin5Fill } from 'react-icons/ri'
//Hooks
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//Utils
import { removeFormAsync, updateFormAsync } from 'features/forms/formsSlice'
import { setError } from 'features/error/errorSlice'
//pictures
import HubSpotLogo from '../../../../assets/Home/HubSpot Logo.png'
import FormidableLogo from '../../../../assets/Home/FormIdable Logo.jpeg'
import GravityLogo from '../../../../assets/Home/Gravity Logo.png'
import TypeFormLogo from '../../../../assets/Home/TypeForm Logo.png'
import JotFormLogo from '../../../../assets/Home/JotForm Logo.png'

const Form = ({ form }) => {
  const dispatch = useDispatch()
  const allWebsites = useSelector((state) => state.websites)
  const { id, type, testFrequency, url, formId, websites } =
    form

  const foundWebsite = allWebsites.find((website) => website.id === websites[0])

  //? Toggles
  const [emailNotifications, setEmailNotifications] = useState(
    form.emailNotifications,
  )
  const [slackNotifications, setSlackNotifications] = useState(
    form.slackNotifications,
  )
  const [active, setActive] = useState(form.active)
  // const [testEmail, setTestEmail] = useState(form.testEmail)
  // const [redirect, setRedirect] = useState(form.testRedirect)
  //Test frequency
  const [frequency, setFrequency] = useState(testFrequency)
  const toggleSlackNotifications = () => {
    try {
      dispatch(
        updateFormAsync({
          ...form,
          slackNotifications: !form.slackNotifications,
        }),
      )
      setSlackNotifications(!slackNotifications)
    } catch (err) {
      dispatch(setError('Failed to update notifications settings'))
    }
  }
  const toggleEmailNotifications = () => {
    try {
      dispatch(
        updateFormAsync({
          ...form,
          emailNotifications: !form.emailNotifications,
        }),
      )
      setEmailNotifications(!emailNotifications)
    } catch (err) {
      dispatch(setError('Failed to update notifications settings'))
    }
  }
  const changeFrequency = (frequency) => {
    try {
      dispatch(updateFormAsync({ ...form, testFrequency: frequency }))
      setFrequency(frequency)
    } catch (err) {
      dispatch(setError('Failed to change testing frequency'))
    }
  }

  //? Removing a form
  const removeForm = () => {
    try {
      dispatch(removeFormAsync(id))
    } catch (err) {
      dispatch(setError('Failed to remove the form'))
    }
  }
  return (
    <div
      className={`p-4 min-h-[100px] flex-col gap-2  sm:flex-row-reverse tabShape w-full mb-5 flex justify-between items-center border border-gray-700 ${
        form.status === 'Failed' && 'border-2 border-red-500'
      }`}
    >
      <button
        className="self-end text-primary btn btn-circle sm:hidden"
        onClick={removeForm}
      >
        <RiDeleteBin5Fill />
      </button>
      <button className="hidden sm:block btn btn-primary" onClick={removeForm}>
        remove
      </button>
      <div className="flex flex-col w-full gap-4 md:flex-row">
        <div className=" w-full lg:max-w-[450px] break-all ">
          <div className="flex items-center gap-2">
            <p>{type}</p>
            <img
              src={
                (type === 'HubSpot' && HubSpotLogo) ||
                (type === 'Formidable' && FormidableLogo) ||
                (type === 'Gravity' && GravityLogo) ||
                (type === 'Typeform' && TypeFormLogo) ||
                (type === 'Jotform' && JotFormLogo)
              }
              alt="type of the form"
              className="w-5 h-5 bg-white rounded-full"
            />
          </div>
          <p>Form ID: {formId}</p>
          <p>{foundWebsite?.name}</p>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full text-blue-500 break-all"
          >
            {url}
          </a>
          {form.formResults.length > 0 && form.status === 'Failed' && (
            <p className="text-red-500">
              Fail Reason:{' '}
              {form.formResults[form.formResults.length - 1].failReason}
            </p>
          )}
        </div>
        <div className="flex flex-col justify-between w-full gap-5 p-6 xs:p-0 xs:flex-row">
          <div className="items-center flex-1 text-sm sm:flex sm:justify-center">
            <div>
              <div className="flex items-center justify-between gap-2 mb-2">
                <p>Slack notifications:</p>
                <input
                  type="checkbox"
                  className="toggle toggle-success"
                  defaultChecked={slackNotifications}
                  onChange={toggleSlackNotifications}
                />
              </div>
              <div className="flex items-center justify-between mb-2">
                <p>Email notifications:</p>
                <input
                  type="checkbox"
                  className="toggle toggle-success"
                  defaultChecked={emailNotifications}
                  onChange={toggleEmailNotifications}
                />
              </div>
              <div className="flex justify-between ">
                <p>Active:</p>

                <input
                  type="checkbox"
                  className="toggle toggle-success"
                  defaultChecked={active}
                  onChange={() => {
                    dispatch(updateFormAsync({ ...form, active: !active }))
                    setActive(!active)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start flex-1 h-full xs:justify-center">
            <div>
              <p className="font-semibold ">Test frequency:</p>
              <div className="flex items-center justify-between mb-2">
                <p className="text-sm">Day</p>
                <input
                  type="radio"
                  defaultChecked={frequency === 'daily'}
                  value={'daily'}
                  name={`frequency ${id}`}
                  onChange={() => {
                    changeFrequency('daily')
                  }}
                  className="radio radio-primary"
                />
              </div>
              <div className="flex items-center justify-between ">
                <p className="text-sm">Week</p>
                <input
                  type="radio"
                  defaultChecked={frequency === 'weekly'}
                  name={`frequency ${id}`}
                  value={'weekly'}
                  onChange={() => {
                    changeFrequency('weekly')
                  }}
                  className="radio radio-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form
