import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//Hooks
import useInput from 'hooks/useInput'
import axios from '../api/axios'
import { useDispatch } from 'react-redux'
//Utils
import { setError } from 'features/error/errorSlice'
//Schemas
import { emailSchema } from 'schemas/validation'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [err, setErr] = useState(false)

  const [email, emailReset, emailAtribs] = useInput('email', ' ')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  //Resetting error message
  useEffect(() => {
    setTimeout(() => {
      setErr(false)
    }, 3000)
  }, [err])
  //Validating email
  useEffect(() => {
    setValidEmail(!emailSchema.validate(email).error)
  }, [email])

  const navigate = useNavigate()
  const handleSendEmail = async (e) => {
    e.preventDefault()
    try {
      if (!validEmail) return setErr('Invalid Email format')
      await axios.post('/user/forgot-password', { email })
      emailReset()
      navigate('/')
    } catch (error) {
      console.error(error.message)
      dispatch(setError('Something went wrong, please try again later'))
    }
  }

  return (
    <div className=" px-4 mx-auto py-[200px] sm:px-6 lg:px-8 flex justify-center flex-col items-center gap-6">
      <h1 className="text-xl font-semibold">
        Forgot Your Password? Don't Worry.
      </h1>
      <p className="max-w-[600px] ">
        We understand that forgetting your password can be frustrating, but do
        not worry - we've got you covered. Here's what we need you to do:
      </p>
      <ul className="list-disc max-w-[600px]">
        <li>
          Please enter your email address associated with this account in the
          space provided below.
        </li>
        <li>Click on the "Send Email" button.</li>
        <li>
          Check your emails, you will receive a message from us that contains a
          password reset token. Please keep this token safe and confidential,
          you will need it to reset your password. Please remember, the system
          will only generate a single reset token per request. If you've made
          multiple requests, only the most recent one will be honored. Also
          remember to check your spam folder if you can't see the email from us
          within few minutes. Ensure you reset your password immediately after
          receiving the reset token. For security reasons, the reset token will
          expire after a certain period.
        </li>
      </ul>
      <form className="relative w-full mx-auto mb-0 space-y-4 max-w-[400px]">
        <p
          className={` ${
            err ? '' : 'offscreen'
          } absolute left-0 top-[-30px] text-red-400 `}
        >
          {err}
        </p>
        <div className="relative w-full ">
          <label htmlFor="email" className="sr-only">
            Email
          </label>

          <div className="relative w-full ">
            <input
              type="email"
              className="w-full p-4 text-sm border-gray-200 rounded-lg shadow-sm pe-12"
              placeholder="Enter email"
              id="email"
              {...emailAtribs}
              aria-invalid={!validEmail && email && emailFocus}
              aria-describedby="email-note"
              onBlur={() => setEmailFocus(false)}
              onFocus={() => setEmailFocus(true)}
              required
            />

            <span className="absolute inset-y-0 grid px-4 end-0 place-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </span>
            <p
              id="email-note"
              className={`bg-black w-full text-slate-50 p-2 z-10 absolute rounded-lg ${
                !validEmail && email && emailFocus ? '' : 'hidden'
              }`}
            >
              Must start with onre or more alphanumeric characters, dots,
              percent signs, plus signs, or hyphens.
              <br />
              Followed by an at sign @
              <br />
              Followed by one or more alphanumeric characters, dots, or hyphens.
              <br />
              Followed by a dot.
              <br />
              End it need's to end with two or more letters.
            </p>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSendEmail}
        >
          Send Email
        </button>
      </form>
    </div>
  )
}

export default ForgotPassword
