import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
const Hero = () => {
  const auth = useSelector((state) => state.auth)

  return (
    <div className="min-h-screen hero ">
      <div className="text-center hero-content">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Formibly</h1>
          <h2 className="text-2xl font-bold">
            Capture every lead with confidence.
          </h2>
          <p className="py-6">
            Formibly tests your website forms so you never miss a lead. It
            automatically checks to ensure forms work and that every submission
            reaches your inbox.
          </p>
          <Link
            to={Object.keys(auth).length !== 0 ? '/forms' : '/login'}
            className="btn btn-primary"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
