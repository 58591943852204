import React, { useRef, useState, useEffect } from 'react'

import axios from 'api/axios'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'

//Components
import UserInfo from './Components/UserInfo'
import Pagination from './Components/Pagination'
import Description from './Components/Description'
import { PaymentForm } from './Components/PaymentForm'
//Hooks
import { useDispatch, useSelector } from 'react-redux'
//Utils
import { setError } from 'features/error/errorSlice'
//Schemas
import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  passwordSchema,
} from 'schemas/validation'
//----------------------
const REGISTER_URL = '/user/register'
//----------------------
const Login = () => {
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const userInfo = useSelector((state) => state.userInfo)
  const { firstName, lastName, email, pwd, matchPwd } = userInfo
  const [step, setStep] = useState(1)

  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const validateInputs = () => {
    const v1 = !firstNameSchema.validate(firstName).error
    const v2 = !lastNameSchema.validate(lastName).error
    const v3 = !emailSchema.validate(email).error
    const v4 = !passwordSchema.validate(pwd).error
    const v5 = pwd === matchPwd

    return v1 && v2 && v3 && v4 && v5
  }

  const changePage = (page) => {
    if (page === 2) {
      if (validateInputs()) {
        step < 2 && setStep(step + 1)
      } else {
        setErrMsg('All fields are required')
      }
    } else {
      step > 1 && setStep(step - 1)
    }
  }

  //Deleting error message after some time
  useEffect(() => {
    setTimeout(() => {
      setErrMsg('')
    }, 3000)
  }, [errMsg])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (!firstName || !lastName || !email || !pwd || !matchPwd) {
      setErrMsg('All fields are required')
      errRef.current.focus()
      return
    }

    if (!validateInputs()) {
      setErrMsg('Invalid input')
      errRef.current.focus()
      return
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: userInfo.email,
        },
      })

      if (!error) {
        await axios.post(
          REGISTER_URL,
          {
            payment_method: paymentMethod.id,
            email: userInfo.email,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            pwd: userInfo.pwd,
            confirm_pwd: userInfo.matchPwd,
            plan: userInfo.plan,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        )

        Navigate('/login')
      } else {
        console.error(error.message)
        setErrMsg(error.message)
      }
    } catch (err) {
      dispatch(setError(err.response.data.message))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <section>
        <div>
          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div>
              <Description />

              <div className="h-[30px] relative">
                <p
                  ref={errRef}
                  className={`absolute top-0 left-0 ${
                    errMsg === '' && 'hidden'
                  } text-red-600`}
                >
                  {errMsg}
                </p>
              </div>
              <div
                className={`flex items-center justify-center ${
                  !loading && 'hidden'
                }`}
              >
                <span className="loading loading-spinner loading-lg"></span>
              </div>

              <div className={`${loading && 'hidden'} min-h-[360px]`}>
                <UserInfo page={step} />
                <PaymentForm page={step} />
              </div>

              <Pagination step={step} changePage={changePage} />

              <div className="flex flex-wrap gap-2 mt-10 sm:items-center sm:gap-4">
                <button
                  onClick={handleSubmit}
                  disabled={step === 1 || !validateInputs() ? true : false}
                  className={`btn btn-primary `}
                >
                  Subscribe
                </button>

                <p className="mt-2 text-sm sm:mt-0">
                  Already have an account?
                  <a
                    href="/login"
                    className="ml-2 underline text-primary hover:text-primary-color"
                  >
                    Log in
                  </a>
                  .
                </p>
              </div>
            </div>
          </main>
        </div>
      </section>
    </>
  )
}

export default Login
