import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
//Hooks
import { useSelector } from 'react-redux'
const RequireAuth = ({ allowedPlans }) => {
  const auth = useSelector((state) => state.auth)
  const location = useLocation()
  const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined

  const plan = decoded?.UserInfo?.plan || ''

  return allowedPlans?.includes(plan) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    <Navigate to="unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default RequireAuth
