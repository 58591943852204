//Components
import { FaArrowDownWideShort } from 'react-icons/fa6'
//Hooks
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
//Utils
import {
  fetchWebsitesAsync,
  addIntegration,
} from 'features/website/websiteSlice'
import { setError } from 'features/error/errorSlice'
const Integrations = (props) => {
  const dispatch = useDispatch()
  const websites = useSelector((state) => state.websites)
  const defaultWebsite = websites.find(
    (website) => website.id === props.website,
  )
  useEffect(() => {
    dispatch(fetchWebsitesAsync())
  }, [])

  //Select
  const options = websites.map((website) => ({
    value: website.name,
    label: website.name,
  }))
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    defaultWebsite
      ? { value: defaultWebsite.name, label: defaultWebsite.name }
      : { value: '', label: 'Select a website' },
  )
  const [selectedWebsite, setSelectedWebsite] = useState({ integrations: [] })

  //Inputs
  const [gravityKey, setGravityKey] = useState('')
  const [gravitySecret, setGravitySecret] = useState('')
  const [hubspotToken, setHubspotToken] = useState('')

  const [formidableKey, setFormidableKey] = useState('')
  const [typeformKey, setTypeformKey] = useState('')
  const [jotformKey, setJotformKey] = useState('')

  useEffect(() => {
    const website = websites.find(
      (website) => website.name === selectedOption?.value,
    )
    const gravity = website?.integrations.find(
      (integration) => integration.type === 'Gravity',
    )

    const hubspot = website?.integrations.find(
      (integration) => integration.type === 'HubSpot',
    )
    const formidable = website?.integrations.find(
      (integration) => integration.type === 'Formidable',
    )
    const typeform = website?.integrations.find(
      (integration) => integration.type === 'Typeform',
    )
    const jotform = website?.integrations.find(
      (integration) => integration.type === 'Jotform',
    )
    setGravityKey(gravity?.key || '')
    setGravitySecret(gravity?.secret || '')
    setHubspotToken(hubspot?.accessToken || '')
    setFormidableKey(formidable?.key || '')
    setTypeformKey(typeform?.key || '')
    setJotformKey(jotform?.key || '')
    setSelectedWebsite(website)
  }, [selectedOption, websites])

  const addKey = (type, key, secret) => {
    try {
      dispatch(
        addIntegration({
          id: selectedWebsite.id,
          type,
          key,
          secret,
        }),
      )
    } catch (err) {
      dispatch(setError('Failed to add integration'))
    }
  }

  return (
    <section className="flex flex-col items-start gap-2  max-w-[600px]">
      <h3 className="text-slate-50">Api Key's</h3>
      <details className="w-full dropdown" open={dropdownIsOpen}>
        <summary
          className="w-full btn relatrive"
          onClick={(event) => {
            event.preventDefault()
            setDropdownIsOpen((isOpen) => !isOpen)
          }}
        >
          {selectedOption.label}
          <FaArrowDownWideShort className="absolute right-2 " />
        </summary>
        <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
          {options.map((option) => (
            <li key={option.value}>
              <p
                onClick={() => {
                  setSelectedOption(option)
                  setDropdownIsOpen(false)
                }}
              >
                {option.label}
              </p>
            </li>
          ))}
        </ul>
      </details>

      <label htmlFor="">HubSpot</label>
      <div className="flex flex-col justify-center w-full gap-2 xs:flex-row">
        {selectedOption.value && (
          <a
            href={`https://app-eu1.hubspot.com/oauth/authorize?client_id=ef91cbe6-4f70-4df0-ba2f-b5293216fcb7&redirect_uri=${process.env.REACT_APP_BASE_URL}/website/oauth-callback&scope=forms&state=${selectedWebsite?.id}`}
            className="flex-2 btn btn-primary"
          >
            {hubspotToken ? 'Reinstall HubSpot' : 'Install HubSpot'}
          </a>
        )}

        <input
          type="text"
          placeholder="HubSpot Token"
          className="xs:flex-1 input input-bordered"
          value={hubspotToken}
          disabled
        />
      </div>

      <label htmlFor="">Gravity</label>
      <div className="flex flex-col items-center justify-center w-full gap-2 xs:flex-row">
        <input
          type="text"
          placeholder="Gravity Key"
          className="w-full input input-bordered"
          value={gravityKey}
          onChange={(e) => {
            setGravityKey(e.target.value)
          }}
          onBlur={() => {
            addKey('Gravity', gravityKey, gravitySecret)
          }}
        />
        <input
          type="text"
          className="w-full input input-bordered"
          value={gravitySecret}
          onChange={(e) => {
            setGravitySecret(e.target.value)
          }}
          onBlur={() => {
            addKey('Gravity', gravityKey, gravitySecret)
          }}
          placeholder="Gravity Secret"
        />
      </div>
      <label htmlFor="">Formidable</label>
      <input
        type="text"
        placeholder="Formidable"
        className="w-full input input-bordered"
        value={formidableKey}
        onChange={(e) => {
          setFormidableKey(e.target.value)
        }}
        onBlur={() => {
          addKey('Formidable', formidableKey)
        }}
      />
      <label htmlFor="">Typeform</label>
      <input
        type="text"
        placeholder="Typeforms"
        className="w-full input input-bordered"
        value={typeformKey}
        onChange={(e) => {
          setTypeformKey(e.target.value)
        }}
        onBlur={() => {
          addKey('Typeform', typeformKey)
        }}
      />
      <label htmlFor="">Jotform</label>
      <input
        type="text"
        placeholder="Jotforms"
        className="w-full input input-bordered"
        value={jotformKey}
        onChange={(e) => {
          setJotformKey(e.target.value)
        }}
        onBlur={() => {
          addKey('Jotform', jotformKey)
        }}
      />
    </section>
  )
}

export default Integrations
