import React from 'react'
//Images
import HubSpot from 'assets/Home/HubSpot Logo.png'
import FormIdable from 'assets/Home/FormIdable Logo.jpeg'
import Gravity from 'assets/Home/Gravity Logo.png'
import Typeform from 'assets/Home/TypeForm Logo.png'
import JotForm from 'assets/Home/JotForm Logo.png'

const Integrations = () => {
  return (
    <div className="mb-10">
      <section className=" mx-auto max-w-[600px]">
        <h3 className="text-primary">INTEGRATIONS</h3>
        <h2>Connect with Your Favorite Form Builders</h2>
        <p className="!max-w-[700px]">
          It's super easy to set up the testing app to form builders like
          HubSpot, Formidable, Typeforms, Gravity Forms, and Jotforms using
          their API keys. We'll even show you how to find the API key for your
          form-building platform. Our team is available to help answer
          questions, troubleshoot issues, and make sure you get the most value
          out of Formibly.
        </p>
      </section>
      <section className="py-10 mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 max-w-[700px]">
        <div className="flex items-center justify-center px-4 mb-10 bg-gray-200">
          <img
            src={HubSpot}
            alt="HubSpot logo"
            className="w-[50px] h-[50px] bg-slate-50 rounded-full relative bottom-[50%]"
          />
        </div>
        <div className="flex items-center justify-center px-4 mb-10 bg-gray-200">
          <img
            src={FormIdable}
            alt="FormIdable logo"
            className="w-[50px] h-[50px] bg-slate-50 rounded-full relative bottom-[50%]"
          />
        </div>{' '}
        <div className="flex items-center justify-center px-4 mb-10 bg-gray-200">
          <img
            src={Gravity}
            alt="Gravity logo"
            className="w-[50px] h-[50px] bg-slate-50 rounded-full relative bottom-[50%]"
          />
        </div>{' '}
        <div className="flex items-center justify-center px-4 mb-10 bg-gray-200">
          <img
            src={Typeform}
            alt="TypeForms logo"
            className="w-[50px] h-[50px] bg-slate-50 rounded-full relative bottom-[50%]"
          />
        </div>{' '}
        <div className="flex items-center justify-center px-4 mb-10 bg-gray-200">
          <img
            src={JotForm}
            alt="JotForms logo"
            className="w-[50px] h-[50px] bg-slate-50 rounded-full relative bottom-[50%]"
          />
        </div>
      </section>
      <p className="text-center mx-auto max-w-[700px]">
        Plus, our developers are constantly working to improve and expand the
        app's capabilities. Keep an eye out for additional platform integrations
        in the future based on popular request.
      </p>
    </div>
  )
}

export default Integrations
