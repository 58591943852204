import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'api/axios'

export const addWebsiteAsync = createAsyncThunk(
  'websites/addWebsiteAsync',
  async ({ name }, { getState }) => {
    const { accessToken } = getState().auth

    const response = await axiosPrivate.post(
      '/website/add',
      { name },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    return response.data.website
  },
)
export const fetchWebsitesAsync = createAsyncThunk(
  'websites/fetchWebsitesAsync',
  async (_, { getState }) => {
    const { accessToken } = getState().auth

    const response = await axiosPrivate.get('/website/get', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  },
)

export const editWebsiteNameAsync = createAsyncThunk(
  'websites/editWebsiteName',
  async ({ id, name }, thunkAPI) => {
    const { accessToken } = thunkAPI.getState().auth

    try {
      await axiosPrivate.patch(
        '/website/changeName',
        { id, name },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )

      return { id, name }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
)
export const removeWebsiteAsync = createAsyncThunk(
  'websites/removeWebsite',
  async ({ id }, thunkAPI) => {
    const { accessToken } = thunkAPI.getState().auth
    try {
      await axiosPrivate.delete(`/website/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
)
export const toggleNotifications = createAsyncThunk(
  'websites/toggleNotifications',
  async ({ id, notifications }, thunkAPI) => {
    const { accessToken } = thunkAPI.getState().auth
    try {
      await axiosPrivate.patch(
        '/website/changeNotifications',
        { id, notifications },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      return { id, notifications }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
)
export const toggleActive = createAsyncThunk(
  'websites/toggleActive',
  async ({ id, active }, thunkAPI) => {
    const { accessToken } = thunkAPI.getState().auth
    try {
      await axiosPrivate.patch(
        '/website/changeActive',
        { id, active },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      return { id, active }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
)
export const addIntegration = createAsyncThunk(
  'websites/addIntegration',

  async ({ id, type, key, secret }, thunkAPI) => {
    const { accessToken } = thunkAPI.getState().auth
    try {
      await axiosPrivate.post(
        '/website/addApiKey',
        { id, type, key, secret },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )

      return { id, type, key, secret }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
)

const initialState = []

const websiteSlice = createSlice({
  name: 'websites',
  initialState,
  reducers: {
    addWebsite: (state, action) => {
      console.log(action.payload)
      state.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsitesAsync.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(editWebsiteNameAsync.fulfilled, (state, action) => {
        const { id, name } = action.payload
        const website = state.find((website) => website.id === id)
        if (website) {
          website.name = name
        }
      })
      .addCase(editWebsiteNameAsync.rejected, (state, action) => {
        console.error('Failed to edit website name:', action.error)
      })
      .addCase(removeWebsiteAsync.fulfilled, (state, action) => {
        const index = state.findIndex(
          (website) => website.id === action.payload,
        )
        if (index !== -1) {
          state.splice(index, 1)
        }
      })
      .addCase(addWebsiteAsync.fulfilled, (state, action) => {
        state.push(action.payload)
      })
      .addCase(toggleNotifications.fulfilled, (state, action) => {
        const { id, notifications } = action.payload
        const website = state.find((website) => website.id === id)
        if (website) {
          website.notifications = notifications
        }
      })
      .addCase(toggleActive.fulfilled, (state, action) => {
        const { id, active } = action.payload
        const website = state.find((website) => website.id === id)
        if (website) {
          website.active = active
        }
      })
      .addCase(addIntegration.fulfilled, (state, action) => {
        const { id, type, key, secret } = action.payload
        console.log(action.payload)
        const website = state.find((website) => website.id === id)
        console.log(website)
        if (website) {
          const integration = website.integrations.find(
            (integration) => integration.type === type,
          )
          if (integration) {
            console.log(integration)
            integration.key = key
            integration.secret = secret
          } else {
            website.integrations.push({ type, key })
          }
        }
      })
  },
})
export const { addWebsite } = websiteSlice.actions
export default websiteSlice.reducer
