//Hooks
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Utils
import { fetchWebsitesAsync } from 'features/website/websiteSlice'
import { setError } from 'features/error/errorSlice'
//Components
import Website from './components/Website'
//import CreatingWebsite from './components/CreatingWebsite'
import FilterWebsite from './components/FilterWebsite'
const Websites = () => {
  const dispatch = useDispatch()
  const forms = useSelector((state) => state.forms)
  const websites = useSelector((state) => state.websites)
  useEffect(() => {
    try {
      dispatch(fetchWebsitesAsync())
    } catch (err) {
      dispatch(setError('Failed to fetch websites'))
    }
  }, [dispatch])

  //Filter
  const [filter, setFilter] = useState('')
  const [filterName, setFilterName] = useState('')
  const [filteredWebsites, setFilteredWebsites] = useState(websites)

  useEffect(() => {
    setFilteredWebsites(websites)
  }, [websites])

  const changeFilter = (newFilter) => {
    filter === newFilter ? setFilter('') : setFilter(newFilter)
  }

  useEffect(() => {
    let filtered = websites

    if (filter === 'Deactivated') {
      filtered = filtered.filter((website) => !website.active)
    } else if (filter === 'Notifications') {
      filtered = filtered.filter((website) => !website.notifications)
    }

    if (filterName !== '') {
      filtered = filtered.filter((website) =>
        website.name.toLowerCase().includes(filterName.toLowerCase()),
      )
    }
    if (filter === 'Failed') {
      const newFiltered = websites.filter((website) => {
        const websiteForms = website.forms.map((form) =>
          forms.find((f) => f.id === form),
        )
        const failedForms = websiteForms.filter(
          (form) => form.status === 'Failed',
        )
        if (failedForms.length > 0) return true
      })
      filtered = newFiltered
    }
    setFilteredWebsites(filtered)
  }, [filter, filterName, websites])
  return (
    <section>
      {/* <CreatingWebsite /> */}
      <div className="divider"></div>
      <FilterWebsite
        filter={filter}
        changeFilter={changeFilter}
        setFilterName={setFilterName}
      />
      <div className="w-full my-6">
        {filteredWebsites?.map((website, index) => (
          <Website key={index} website={website} />
        ))}
      </div>
    </section>
  )
}

export default Websites
