// features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetAuth: () => {
      return initialState;
    },
  },
})

export const { setAuth , resetAuth } = authSlice.actions

export default authSlice.reducer
