import React, { useState } from 'react'
import useLogout from 'hooks/useLogout'
import { useSelector } from 'react-redux'

export const Nav = () => {
  const auth = useSelector((state) => state.auth)

  const logout = useLogout()
  const [showMenu, setShowMenu] = useState(false)
  return (
    <header className="navbar bg-base-200 shadow-xl rounded-xl max-w-[600px] w-[95%] mx-auto top-5 relative">
      <div className="flex-1">
        <a className="text-xl btn btn-ghost text-primary" href="/">
          Formibly
        </a>
      </div>
      <div className="flex-none">
        <button
          className="btn btn-square btn-ghost"
          onClick={() => {
            setShowMenu(!showMenu)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-5 h-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
            ></path>
          </svg>
        </button>
      </div>
      <ul
        className={`absolute top-[110%] right-0 w-[150px] menu bg-base-200 rounded-box ${
          showMenu ? 'block' : 'hidden'
        }`}
      >
        <li>
          <ul>
            {Object.keys(auth).length !== 0 && (
              <li>
                <a href="/forms">Dashboard</a>
              </li>
            )}
            <li>
              <a href="/login">Login</a>
            </li>
            <li>
              <a href="/register">Register</a>
            </li>
            {Object.keys(auth).length !== 0 && (
              <li>
                <button
                  onClick={() => {
                    logout()
                  }}
                >
                  Logout
                </button>
              </li>
            )}
          </ul>
        </li>
      </ul>
    </header>
  )
}
export default Nav
