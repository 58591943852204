import React, { useState } from 'react'
import Logo from '../assets/Formibly-white-logo.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaArrowDown } from 'react-icons/fa6'
import { GiHamburgerMenu } from 'react-icons/gi'
import useLogout from '../hooks/useLogout'
const DashboardNavTop = () => {
  const logout = useLogout()
  const [dropdownsOpen, setDropdownsOpen] = useState({
    dropdown1: false,
    dropdown2: false,
  })
  const auth = useSelector((state) => state.auth)
  const firstName = auth.firstName
  const lastName = auth.lastName
  const profilePhoto = auth.profilePhoto

  // Toggle dropdown visibility
  const toggleDropdown = (dropdownId) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [dropdownId]: !prevState[dropdownId],
    }))
  }

  // Close dropdown
  const closeDropdown = (dropdownId) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [dropdownId]: false,
    }))
  }

  return (
    <div className="flex w-full bg-gray-900 text-slate-50">
      <div className="flex items-center justify-start flex-1 ml-5">
        <div className="dropdown ">
          <div
            tabIndex={0}
            role="button"
            className="m-1 bg-gray-900 btn btn-ghost btn-circle btn-sm dropdown-toggle"
            onClick={() => toggleDropdown('dropdown1')}
          >
            <GiHamburgerMenu />
          </div>
          {dropdownsOpen.dropdown1 && (
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-gray-700 rounded-box z-[1] w-52 p-2 shadow"
            >
              <li onClick={() => closeDropdown('dropdown1')}>
                <Link to="/forms">Forms</Link>
              </li>
              <li onClick={() => closeDropdown('dropdown1')}>
                <Link to="/websites">Websites</Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center flex-1">
        <Link
          to="/"
          className="flex items-center h-full px-5 hover:bg-gray-950"
        >
          <img
            src={Logo}
            alt="formibly logo"
            className="w-[140px] min-w-[100px]"
          />
        </Link>
      </div>
      <div className="flex items-center justify-end flex-1">
        <div
          className="px-5 py-2 dropdown hover:bg-gray-950 dropdown-bottom dropdown-end"
          onClick={() => toggleDropdown('dropdown2')}
        >
          <div
            tabIndex={0}
            role="button"
            className="flex items-center justify-center gap-2 "
          >
            <img
              src={profilePhoto}
              className="hidden object-cover w-12 h-12 rounded-full sm:block"
              alt="User profile"
            />
            <p className="hidden xs:block">
              {firstName} {lastName}
            </p>
            <div className="flex items-center justify-center w-5 h-5">
              <FaArrowDown />
            </div>
          </div>
          {dropdownsOpen.dropdown2 && (
            <ul
              tabIndex={0}
              className="dropdown-content menu  rounded-box z-[1] w-52 p-2 shadow mt-4 bg-gray-700"
            >
              <li>
                <Link
                  to="/settings"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  User settings
                </Link>
              </li>
              <li>
                <Link
                  to="/documents"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  Documents
                </Link>
              </li>
              <li>
                <Link
                  to="/billing"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  Billing
                </Link>
              </li>
              <li>
                <Link
                  to="/instructions"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  Instructions
                </Link>
              </li>
              <li>
                <Link
                  to="/support"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown5')
                  }}
                >
                  Support
                </Link>
              </li>
              <li>
                <Link
                  to="/reports"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown4')
                  }}
                >
                  Reports
                </Link>
              </li>

              <li>
                <button
                  className="mt-5 btn btn-sm btn-primary"
                  onClick={logout}
                >
                  Logout
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashboardNavTop
