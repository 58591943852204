import React, { useState, useEffect } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import axios from 'api/axios'
//Components
import Loading from 'components/Loading'
//Hooks
import { useDispatch, useSelector } from 'react-redux'
import { updateUserInfo } from 'features/userInfo/userInfoSlice'
const PRICING_PLANS = [
  {
    name: 'Basic',
  },
  {
    name: 'Premium',
  },
  {
    name: 'Pro',
  },
]

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#7480ff',
      color: '#7480ff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#7480ff' },
      '::placeholder': { color: '#7480ff' },
      borderColor: '#7480ff',
    },
    invalid: {
      iconColor: '#d14f4f',
      color: '#d14f4f',
    },
  },
}

export const PaymentForm = (props) => {
  const dispatch = useDispatch()
  const [pricingPlan, setPricingPlan] = useState(PRICING_PLANS[0])
  const [plansList, setPlansList] = useState([])

  //Loading plans from API
  const loadPlans = async () => {
    try {
      const res = await axios.get('user/retrieve-plans')
      setPlansList(res.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    loadPlans()
  }, [])

  useEffect(() => {
    dispatch(updateUserInfo({ plan: pricingPlan.name }))
  }, [pricingPlan])

  return (
    <div
      className={`${
        props.page === 2 ? '' : 'hidden'
      } flex flex-col justify-between col-span-6`}
    >
      <div className="flex flex-col items-center justify-around gap-2 my-4 md:flex-row">
        {plansList.length !== 0 ? (
          plansList.map((plan, index) => (
            <div
              key={index}
              className={`${
                PRICING_PLANS[index] === pricingPlan ? 'border-primary' : ''
              } card border sm:max-w-[180px] w-[95%]`}
            >
              <div className="card-body ">
                {' '}
                <h2 className="text-xl font-bold">{plan.name}</h2>
                <p className="text-lg">{plan.price / 100}$/month</p>
                <button
                  onClick={() => {
                    setPricingPlan(PRICING_PLANS[index])
                  }}
                  className={`btn ${
                    PRICING_PLANS[index] === pricingPlan ? 'btn-primary' : ''
                  }`}
                >
                  Select
                </button>
              </div>
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>

      <div className="space-y-4">
        <fieldset className="p-4 rounded-md">
          <div className="flex flex-col">
            <CardElement
              options={CARD_OPTIONS}
              className="p-2 border rounded-md border-primary "
            />
          </div>
        </fieldset>
      </div>
    </div>
  )
}
