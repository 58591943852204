import React, { useRef, useState } from 'react'
//Hooks
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
//React Icons
import { CiEdit } from 'react-icons/ci'
//Utils
import { setError } from 'features/error/errorSlice'
import { setAuth } from 'features/auth/authSlice'
//Components
import UpdateInfo from './UpdateInfo'
const Credentials = () => {
  const fileInputRef = useRef()
  const axiosPrivate = useAxiosPrivate()
  const dispatch = useDispatch()
  //Components State
  const auth = useSelector((state) => state.auth)
  const [err, setErr] = useState(null)
  //Image upload
  const onDivClick = () => {
    fileInputRef.current.click()
  }

  const onSubmit = async (e, fileToUpload) => {
    e.preventDefault()
    const formData = new FormData()
    if (fileToUpload) {
      formData.append('userPhoto', fileToUpload)

      try {
        const res = await axiosPrivate.post('/user/change-picture', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        const imageRes = await axiosPrivate.get(
          `/profile-picture/${res.data.file}`,
          {
            responseType: 'blob',
          },
        )

        const blobUrl = URL.createObjectURL(imageRes.data)
        const newAuth = { ...auth, profilePhoto: blobUrl }
        dispatch(setAuth(newAuth))
      } catch (err) {
        if (!err?.response) {
          console.error('Server shut down')
        }
        dispatch(setError(err?.message || 'Error in uploading image'))
        console.error(err?.message)
      }
    } else {
      dispatch(setErr('No file selected'))
      console.error('No file selected')
    }
  }

  const onChange = async (e) => {
    const fileToUpload = e.target.files[0]
    // setFile(fileToUpload)
    await onSubmit(e, fileToUpload)
  }

  return (
    <div>
      <div className="flex flex-wrap items-center justify-center gap-2 sm:justify-start text-slate-50">
        <div className="relative w-32 h-32 overflow-hidden rounded-full group">
          <img
            src={auth.profilePhoto}
            alt="User profile"
            className="object-cover w-full h-full "
          />

          <div
            onClick={onDivClick}
            className={`cursor-pointer absolute top-0 left-0 items-center justify-center w-full h-full bg-black/30 text-slate-50 hidden group-hover:flex`}
          >
            <CiEdit size={30} />
          </div>
          <div
            className={`absolute top-0 left-0 z-20 items-center justify-center w-full h-full bg-red-400/80 text-center  ${
              err ? 'flex' : 'hidden'
            }`}
          >
            <p className="font-bold ">{err}</p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-center">
          <h1 className="text-2xl italic font-normal">
            {auth.firstName} {auth.lastName}
          </h1>
          <h2 className="font-normal ">{auth.email}</h2>
        </div>
        <form onSubmit={onSubmit}>
          <div className="custom-file">
            <input
              type="file"
              id="customFile"
              onChange={onChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
          </div>
        </form>
      </div>
      <UpdateInfo />
    </div>
  )
}

export default Credentials
