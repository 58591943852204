import { Outlet } from 'react-router-dom'
//Components
import Nav from './Nav'
const Layout = () => {
  return (
    <main className='relative'>
      <Nav />
      <Outlet />
    </main>
  )
}

export default Layout
