import { axiosPrivate } from '../api/axios'
import { useEffect } from 'react'

//Hooks
import { useSelector } from 'react-redux'
import useRefreshToken from './useRefreshToken'

const useAxiosPrivate = (token) => {
  const refresh = useRefreshToken()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${
            token || auth?.accessToken
          }`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          const newAccessToken = await refresh()
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      },
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh, token])

  return axiosPrivate
}
export default useAxiosPrivate
