import React from 'react'

const FilterWebsite = ({ filter, changeFilter, setFilterName }) => {
  return (
    <div>
      <p>Filter:</p>
      <div className="w-full md:max-w-[700px] flex gap-2">
        <div className="flex flex-col flex-1 gap-2 md:flex-row">
          <button
            className={`btn btn-sm ${
              filter === 'Deactivated' && 'btn-active'
            } flex-1`}
            onClick={() => {
              changeFilter('Deactivated')
            }}
          >
            Deactivated
          </button>
          <button
            className={`btn btn-sm ${
              filter === 'Notifications' && 'btn-active'
            } flex-1`}
            onClick={() => {
              changeFilter('Notifications')
            }}
          >
            Notifications muted
          </button>
        </div>
        <div className="flex flex-col flex-1 gap-2 md:flex-row">
          <button
            className={`btn btn-sm ${filter === 'failed' && 'btn-active'} flex-1`}
            onClick={() => {
              changeFilter('failed')
            }}
          >
            Failed
          </button>
          <input
            type="text"
            className="flex-1 w-full input input-bordered md:w-auto input-sm"
            placeholder="Filter by name"
            onChange={(e) => {
              setFilterName(e.target.value)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterWebsite
