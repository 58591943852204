import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
//Hooks
import { useDispatch } from 'react-redux'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import useInput from 'hooks/useInput'
import useToggle from 'hooks/useToggle'
//Utils
import { setAuth } from 'features/auth/authSlice'
import axios from 'api/axios'
import { useNavigate } from 'react-router-dom'
const LOGIN_URL = '/user/login'
const Login = () => {
  const dispatch = useDispatch()
  const axiosPrivate = useAxiosPrivate()

  const navigate = useNavigate()
  //const location = useLocation()
  //const from = location.state?.from?.pathname || '/'
  const emailRef = useRef()
  const errRef = useRef()

  const [email, emailReset, emailAtribs] = useInput('email', ' ')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [check, toggleCheck] = useToggle('persist', false)

  //Setting the focus on the first input
  useEffect(() => {
    emailRef.current.focus()
  }, [])

  //Deleting error message on input
  useEffect(() => {
    setErrMsg('')
  }, [email, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, pwd }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      )
      const accessToken = response?.data?.accessToken
      const firstName = response?.data?.firstName
      const lastName = response?.data?.lastName
      const profilePhoto = response?.data?.profilePhoto
      const plan = response?.data?.plan
      const permission = response?.data?.permission
      const slackChannel = response?.data?.slackChannel

      const imageRes = await axiosPrivate.get(
        `/profile-picture/${profilePhoto}`,
        {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )

      const blobUrl = URL.createObjectURL(imageRes.data)
      dispatch(
        setAuth({
          plan,
          email,
          accessToken,
          firstName,
          lastName,
          permission,
          profilePhoto: blobUrl,
          slackChannel: slackChannel,
        }),
      )

      emailReset()
      setPwd('')
      navigate('/forms', { replace: true })
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Email or Password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        //setError(true)
        setErrMsg('Login Failed')
      }
      errRef.current.focus()
    }
  }

  return (
    <>
      <div className="flex flex-col items-center px-4 py-16 jusitify-center sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto text-center">
          <h1 className="text-2xl font-bold sm:text-3xl">Get started today!</h1>

          <p className="mt-4 ">
            Start testing forms across platforms with a plan that suits your
            budget and requirements
          </p>
          <div className="h-[20px] my-2">
            <p
              className={`${errMsg ? 'text-red-400' : 'offscreen'}`}
              ref={errRef}
            >
              {errMsg}
            </p>
          </div>
        </div>
        <form
          action=""
          className="flex flex-col max-w-[400px] w-[90%] items-center gap-5"
        >
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <label htmlFor="password" className="sr-only">
            Password
          </label>

          <input
            type="email"
            placeholder="Your Email"
            className="w-full input input-bordered input-primary"
            id="email"
            ref={emailRef}
            {...emailAtribs}
            required
          />
          <input
            type="password"
            id="password"
            autoComplete="off"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            className="w-full input input-bordered input-primary"
            placeholder="Enter password"
          />

          <div className="mr-auto form-control">
            <label className="cursor-pointer label">
              <span className="mr-4 label-text">Remember me</span>
              <input
                onChange={toggleCheck}
                checked={check}
                className="toggle toggle-primary"
                type="checkbox"
                value=""
              />
            </label>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="text-sm text-gray-500">
              No account?
              <a className="ml-2 underline" href="/register">
                Sign up
              </a>
              <br />
              <Link to="/forgot-password" className="underline">
                Forgot password?
              </Link>
            </p>

            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Login
