import React from 'react'

const Description = () => {
  return (
    <div className="max-w-[700px]">
      <h1 className="mt-6 text-2xl font-bold text-primary sm:text-3xl md:text-4xl">
        Welcome to Formibly 🧪
      </h1>
      <p className="mt-4 mb-10 leading-relaxed ">
        Business owners and web developers can stop worrying that their forms
        have silently stopped working. Our daily form tests safeguard your
        revenue stream, so every submission reaches your inbox within the
        critical 24-hour response window consumers expect.
      </p>
    </div>
  )
}

export default Description
