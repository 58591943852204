import React, { useState } from 'react'
import axiosPrivate from 'hooks/useAxiosPrivate'
import { setError } from 'features/error/errorSlice'
import { useDispatch } from 'react-redux'
import { addForm } from 'features/forms/formsSlice'
import { addWebsite } from 'features/website/websiteSlice'
//Components
import Popup from 'reactjs-popup'
import { IoMdClose } from 'react-icons/io'

import Loader from '../../../../components/Loading'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
const CreatingFormV2 = () => {
  const dispatch = useDispatch()

  const [websiteURL, setWebsiteURL] = useState('')
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [forms, setForms] = useState([])
  const [displayForms, setDisplayForms] = useState([])
  const [selectedForms, setSelectedForms] = useState([])
  const axios = axiosPrivate()

  const onSubmit = (e) => {
    e.preventDefault()

    let baseURL
    if (websiteURL) {
      try {
        baseURL = new URL(websiteURL).origin
      } catch (error) {
        console.error('Invalid URL:', error)
        dispatch(setError('Invalid URL format'))

        return
      }
    }
    console.log('Form submitted')
    setLoading(true)
    axios
      .post('/form/scrape', {
        url: baseURL,
      })
      .then((res) => {
        setForms(res.data)

        const uniqueForms = Array.from(
          res.data
            .reduce((map, form) => {
              const key = form.id
              if (!map.has(key)) {
                map.set(key, form)
              }
              return map
            }, new Map())
            .values(),
        )
        console.log(uniqueForms)
        setModalIsOpen(true)
        setDisplayForms(uniqueForms)
      })
      .catch((err) => {
        dispatch(setError('Failed to scrape forms'))

        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function containsForm(selectedForms, formToCheck) {
    return selectedForms.some(
      (form) =>
        form.id === formToCheck.id &&
        JSON.stringify(form.classes) === JSON.stringify(formToCheck.classes),
    )
  }
  const onClick = (form) => {
    if (containsForm(selectedForms, form)) {
      setSelectedForms(selectedForms.filter((f) => f.id !== form.id))
    } else {
      setSelectedForms([...selectedForms, form])
    }
  }
  const addToTesting = () => {
    let baseURL
    if (websiteURL) {
      try {
        baseURL = new URL(websiteURL).origin
      } catch (error) {
        console.error('Invalid URL:', error)
        dispatch(setError('Invalid URL format'))

        return
      }
    }
    //Remove screenshot from forms
    setLoading2(true)
    const allFormsFormatted = forms.map(({ screenshot, ...rest }) => ({
      ...rest,
    }))
    const selectedFormsFormatted = selectedForms.map(
      ({ screenshot, ...rest }) => ({
        ...rest,
      }),
    )

    axios
      .post('/form/add-scraped', {
        websiteBaseUrl: baseURL,
        selectedForms: selectedFormsFormatted,
        allForms: allFormsFormatted,
      })
      .then((res) => {
        const forms = res.data.forms

        const newWebsite = res.data.newWebsite
        dispatch(addWebsite(newWebsite))
        forms.forEach((form) => {
          dispatch(addForm(form))
        })
        setModalIsOpen(false)
      })
      .catch((err) => {
        dispatch(setError('Failed to add forms to testing'))
      })
      .finally(() => {
        setLoading2(false)
      })
  }
  return (
    <div>
      <p>Add a form:</p>
      <div className='flex items-center h-10 '>
        {loading ? (
          <Loader />
        ) : (
          <form className="flex gap-2" onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="Website base URL"
              className="input input-bordered input-sm"
              onChange={(e) => setWebsiteURL(e.target.value)}
            />
            <button className="btn btn-sm btn-primary">Create</button>
          </form>
        )}
      </div>
      <Popup
        open={modalIsOpen}
        closeOnDocumentClick={false}
        modal
        onClick={() => {
          setModalIsOpen((state) => !state)
        }}
      >
        <div className="relative flex flex-col items-center justify-center w-full gap-2">
          <button
            onClick={() => {
              setModalIsOpen(false)
            }}
            className="ml-auto text-2xl hover:text-red-400"
          >
            <IoMdClose />
          </button>
          <p className="font-semibold ">Select forms that you want to test</p>

          <Carousel className="w-full">
            {displayForms.map((form) => (
              <div
                key={form.id}
                className={`${
                  containsForm(selectedForms, form)
                    ? 'border-primary border-[6px] '
                    : ''
                } cursor-pointer min-w-[250px] min-h-[250px] `}
                onClick={() => onClick(form)}
              >
                <a href={form.url} className="text-blue-500 break-all">
                  {form.url}
                </a>
                <p className="break-all">{form.id}</p>
                <img
                 src={form.screenshot ? `data:image/png;base64,${form.screenshot}` : ""}
                  alt="Form screenshot"
                  className="object-cover w-full h-full"
                />
              </div>
            ))}
          </Carousel>

          {loading2 ? (
            <div>
              <Loader />
            </div>
          ) : (
            <button className="btn btn-primary" onClick={addToTesting}>
              Add to testing
            </button>
          )}
        </div>
      </Popup>
    </div>
  )
}

export default CreatingFormV2
