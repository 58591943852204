import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setError } from 'features/error/errorSlice'
const Modal = () => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const error = useSelector((state) => state.error)

  useEffect(() => {
    if (error) {
      setShowModal(true)
      setTimeout(() => {
        dispatch(setError(null))
        setTimeout(() => {
          setShowModal(false)
        }, 1000)
      }, 3000)
    }
  }, [error, dispatch])

  return (
    showModal && (
      <div className="w-[100%] h-screen overflow-hidden absolute">
        <div
          role="alert"
          className={`alert alert-error ${
            error ? 'slide-in' : 'slide-out'
          } max-w-[500px] w-full absolute right-[10px] transform top-[50px] z-[1000]`}
        >
          <span>Error! {error}</span>
        </div>
      </div>
    )
  )
}

export default Modal
