import axios from '../api/axios'
//Hooks
import { useDispatch } from 'react-redux'
import { resetAuth } from '../features/auth/authSlice'

const useLogout = () => {
  const dispatch = useDispatch()

  const logout = async () => {
    dispatch(resetAuth())
    try {
      await axios('/user/logout', {
        withCredentials: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return logout
}

export default useLogout
