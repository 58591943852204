import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'api/axios'

export const fetchFormsAsync = createAsyncThunk(
  'forms/fetchFormsAsync',
  async (_, { getState }) => {
    const { accessToken } = getState().auth
    const response = await axiosPrivate.get('/form/get', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  },
)

export const addFormAsync = createAsyncThunk(
  'forms/addFormAsync',
  async ({ name, type, url, websites, formId }, { getState }) => {
    const { accessToken } = getState().auth
    const response = await axiosPrivate.post(
      '/form/add',
      {
        name,
        type,
        url,
        websites,
        formId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    console.log(response.data.form)
    return response.data.form
  },
)

export const updateFormAsync = createAsyncThunk(
  'forms/updateFormAsync',
  async (form, { getState }) => {
    const { accessToken } = getState().auth
    await axiosPrivate.put(
      `/form/update`,
      {
        id: form.id,
        name: form.name,
        type: form.type,
        url: form.url,
        websites: form.websiteIds,
        testFrequency: form.testFrequency,
        emailNotifications: form.emailNotifications,
        slackNotifications: form.slackNotifications,
        active: form.active,
        testEmail: form.testEmail,
        redirect: form.redirect,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    return form
  },
)
export const updateWebsites = createAsyncThunk(
  'forms/updateWebsites',
  async ({ formId, websites }, { getState }) => {
    console.log(formId, websites)
    const { accessToken } = getState().auth

    await axiosPrivate.put(
      '/form/update-websites',
      { formId, websites },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    return { formId, websites }
  },
)
export const removeFormAsync = createAsyncThunk(
  'forms/removeFormAsync',
  async (id, { getState }) => {
    const { accessToken } = getState().auth
    await axiosPrivate.delete(`/form/remove/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return id
  },
)

const initialState = []

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    addForm: (state, action) => {
      state.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormsAsync.fulfilled, (state, action) => {
      return action.payload
    })

    builder.addCase(addFormAsync.fulfilled, (state, action) => {
      state.push(action.payload)
    })
    builder.addCase(removeFormAsync.fulfilled, (state, action) => {
      return state.filter((form) => form.id !== action.payload)
    })
    builder.addCase(updateFormAsync.fulfilled, (state, action) => {
      const index = state.findIndex((form) => form.id === action.payload.id)
      if (index !== -1) {
        state.splice(index, 1, action.payload)
      }
    })
    builder.addCase(updateWebsites.fulfilled, (state, action) => {
      const index = state.findIndex((form) => form.id === action.payload.formId)

      if (index !== -1) {
        state[index].websites = action.payload.websites
      }
    })
  },
})

export const {
  addForm,
} = formsSlice.actions
export default formsSlice.reducer
