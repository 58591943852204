import { Routes, Route } from 'react-router-dom'
import Layout from 'components/Layout.js'
import DashboardLayout from 'components/DashboardLayout.js'
import RequireAuth from 'components/RequireAuth.js'
import PersistLogin from 'components/PersistLogin.js'
import Stripe from 'components/Stripe.js'
//Pages
import Home from 'pages/Home/Home.js'
import Missing from 'pages/Missing.js'
import Login from 'pages/Home/Login/login.js'
import Register from 'pages/Home/Register/register.js'
import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword'
//Pages dashboard
import Documents from 'pages/Dashboard/Documents/Documents.js'
import Settings from 'pages/Dashboard/Settings/Settings.js'
import Accounts from 'pages/Dashboard/Accounts'
import Websites from 'pages/Dashboard/Website/Websites'
import Forms from 'pages/Dashboard/Forms/Forms'
import Modal from 'components/Modal.js'
const PRICING_PLANS = {
  Basic: '123e4567-e89b-12d3-a456-426614174000',
  Premium: '987e6543-e21b-34d5-b654-426614174001',
  Pro: '567e8901-e23b-45d6-c789-426614174002',
}

function App() {
  return (
    <>
      <Modal />
      <Routes>
        {/* Public Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />}></Route>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route element={<Stripe />}>
              <Route path="register" element={<Register />} />
            </Route>
          </Route>
        </Route>

        <Route element={<PersistLogin />}>
          <Route element={<DashboardLayout />}>
            <Route
              element={
                <RequireAuth
                  allowedPlans={[
                    PRICING_PLANS.Basic,
                    PRICING_PLANS.Premium,
                    PRICING_PLANS.Pro,
                    'canceled',
                  ]}
                />
              }
            >
              <Route path="/websites" element={<Websites />}></Route>
              <Route path="/settings" element={<Settings />}></Route>
              <Route path="/forms" element={<Forms />}></Route>
              <Route path="/documents" element={<Documents />}></Route>
              <Route path="/accounts" element={<Accounts />}></Route>
            </Route>
          </Route>
        </Route>

        <Route path="unauthorized" element={<Missing />} />
        {/* Catch All */}
        <Route path="*" element={<Missing />}></Route>
      </Routes>
    </>
  )
}

export default App
