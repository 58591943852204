import React from 'react'

const Pagination = (props) => {
  const { step, changePage } = props
  return (
    <div className="flex items-center justify-center w-full join">
      <button
        className={`join-item btn ${step === 1 && 'btn-active'}`}
        onClick={() => {
          changePage(1)
        }}
      >
        1
      </button>
      <button
        className={`join-item btn ${step === 2 && 'btn-active'}`}
        onClick={() => {
          changePage(2)
        }}
      >
        2
      </button>
    </div>
  )
}

export default Pagination
